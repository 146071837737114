@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* -------------------------------------------------------------- */
/* Score Over Time - Skeleton */
/* -------------------------------------------------------------- */
.skeleton[class] {
  /* -------------------------------------------------------------- */
  /* Container */
  /* -------------------------------------------------------------- */
  > div:last-child {
    padding-bottom: rem(25px);
    padding-top: rem(5px);
    position: relative;
    > div {
      height: rem(122px);
    }
    /* -------------------------------------------------------------- */
    /* Skeleton */
    /* -------------------------------------------------------------- */
    > div > div {
      height: 100%;
      position: relative;
      > div {
        background-color: #f0f0f0;
        height: rem(1px);
        left: 0;
        position: absolute;
        right: 0;
        &:nth-child(1) {
          background-color: $grey-light;
          bottom: 0;
        }
        &:nth-child(2) {
          bottom: calc(25% - rem(1px));
        }
        &:nth-child(3) {
          bottom: calc(50% - rem(1px));
        }
        &:nth-child(4) {
          bottom: calc(75% - rem(1px));
        }
        &:nth-child(5) {
          bottom: calc(100% - rem(1px));
        }
      }
    }
    /* -------------------------------------------------------------- */
    /* Overlay */
    /* -------------------------------------------------------------- */
    &::after {
      background-color: rgba($white, 0.1);
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 0;
    }
    /* -------------------------------------------------------------- */
    /* Spinner */
    /* -------------------------------------------------------------- */
    > svg {
      color: $blue-pastel-dark;
      height: rem(60px);
      left: 50%;
      margin: rem(-30px) 0 0 rem(-30px);
      position: absolute;
      top: 50%;
      transform: translateY(rem(-12px));
      width: rem(60px);
      z-index: 1;
      * {
        fill: currentcolor;
      }
    }
  }
}
