@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Policy Holders List - Skeleton */
/* ---------------------------------------------------------------- */
.skeleton {
  background: $white;
  border-radius: rem(3px);
  box-shadow: 0 rem(3px) rem(6px) rgba(0, 0, 0, 0.1);
  display: grid;
  margin-bottom: rem(28px);
  overflow: hidden;
  /* ---------------------------------------------------------------- */
  /* Header */
  /* ---------------------------------------------------------------- */
  header {
    align-items: center;
    border-bottom: rem(1px) solid $grey-light;
    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    grid-gap: rem(10px);
    height: rem(44px);
    padding: 0 rem(10px);
    text-transform: uppercase;
    /* ---------------------------------------------------------------- */
    /* Label */
    /* ---------------------------------------------------------------- */
    div {
      color: $grey;
      font-size: rem(8px);
      font-weight: $extra-bold;
      line-height: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Content */
  /* ---------------------------------------------------------------- */
  ul {
    border-bottom: rem(1px) solid $green-pastel-light;
    display: grid;
    overflow-y: auto;
    scrollbar-color: $red-dark transparent;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: rem(8px);
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: rem(8px);
      box-shadow: inset 0 0 rem(6px) rgba(0, 0, 0, 0);
    }
    &::-webkit-scrollbar-thumb {
      background-color: $red-dark;
      border-radius: rem(8px);
    }
  }
  /* ---------------------------------------------------------------- */
  /* Content Item */
  /* ---------------------------------------------------------------- */
  li {
    border-bottom: rem(1px) solid $green-pastel-light;
    position: relative;
    &:last-child {
      border: none;
    }
    &:nth-child(odd) {
      > div {
        background-color: $white;
        &::after {
          @include skeleton-shimmer-color($color: $white);
        }
        > span::after {
          background-color: $grey-light;
        }
      }
    }
    &:nth-child(even) {
      > div {
        background-color: $green-pastel-light;
        &::after {
          @include skeleton-shimmer-color($color: $green-pastel-light);
        }
        > span::after {
          background-color: $grey-light;
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Button */
    /* ---------------------------------------------------------------- */
    > div {
      align-items: center;
      box-sizing: border-box;
      display: grid;
      grid-auto-columns: minmax(0, 1fr);
      grid-auto-flow: column;
      grid-gap: rem(10px);
      height: rem(44px);
      overflow: hidden;
      padding: 0 rem(10px);
      position: relative;
      text-align: left;
      width: 100%;
      &::after {
        @include skeleton-shimmer;
      }
      > span {
        &::after {
          color: transparent;
          font-size: inherit;
          line-height: inherit;
        }
        &:nth-child(1)::after {
          content: 'Nathan';
        }
        &:nth-child(2)::after {
          content: 'Miller';
        }
        &:nth-child(3)::after {
          content: 'geekgrl@outlook.com';
        }
        &:nth-child(4)::after {
          content: '7426774';
        }
        &:nth-child(5)::after {
          content: 'LRB5571591494';
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Value */
    /* ---------------------------------------------------------------- */
    span {
      font-size: rem(14px);
      line-height: rem(17px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &:last-child {
        padding-right: rem(18px);
      }
    }
    /* ---------------------------------------------------------------- */
    /* Chevron */
    /* ---------------------------------------------------------------- */
    div > span > span {
      background-color: $grey-light;
      height: rem(17px);
      position: absolute;
      right: rem(10px);
      top: rem(13.5px);
      width: rem(8px);
      &:last-child {
        padding: 0;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Footer */
  /* ---------------------------------------------------------------- */
  footer {
    align-items: center;
    display: flex;
    font-size: rem(12px);
    height: rem(34px);
    justify-content: flex-end;
    line-height: 1;
    overflow: hidden;
    padding: 0 rem(10px);
    position: relative;
    &::after {
      @include skeleton-shimmer;
      @include skeleton-shimmer-color($color: $white);
    }
    strong {
      font-weight: 700;
    }
    /* ---------------------------------------------------------------- */
    /* Pagination */
    /* ---------------------------------------------------------------- */
    > div:first-child {
      &::after {
        background-color: $grey-light;
        color: transparent;
        content: ' of 385';
      }
      strong {
        &::after {
          background-color: $grey-light;
          color: transparent;
          content: '1 - 20';
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Previous Page Button */
    /* ---------------------------------------------------------------- */
    div:nth-of-type(2) {
      margin-left: rem(12px);
    }
    /* ---------------------------------------------------------------- */
    /* Previous Page Button */
    /* ---------------------------------------------------------------- */
    div:nth-of-type(3) {
      margin-left: rem(6px);
      margin-right: rem(-8px);
    }
    /* ---------------------------------------------------------------- */
    /* Button */
    /* ---------------------------------------------------------------- */
    > div:not(:first-child) {
      align-items: center;
      cursor: wait;
      display: flex;
      height: rem(34px);
      justify-content: center;
      position: relative;
      width: rem(20px);
      /* ---------------------------------------------------------------- */
      /* Button Icon */
      /* ---------------------------------------------------------------- */
      > span {
        background-color: $grey-light;
        height: rem(14.5px);
        width: rem(5px);
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Relative Scrollbar */
  /* ---------------------------------------------------------------- */
  &.relative-scrollbar {
    header,
    footer {
      padding-right: rem(18px);
    }
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
