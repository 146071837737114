@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ------------------------------------------------------------ */
/* Events Summary Skeleton */
/* ------------------------------------------------------------ */
.skeleton {
  /* ------------------------------------------------------------ */
  /* Container */
  /* ------------------------------------------------------------ */
  > div {
    overflow: hidden;
    position: relative;
    &::after {
      @include skeleton-shimmer;
      @include skeleton-shimmer-color($color: $white);
    }
  }
  /* ------------------------------------------------------------ */
  /* Item */
  /* ------------------------------------------------------------ */
  li {
    display: flex;
    @include no-user-select;
    &:nth-child(1) p::after {
      content: 'Speeding Events';
    }
    &:nth-child(2)p::after {
      content: 'Braking Events';
    }
    &:nth-child(3) p::after {
      content: 'Acceleration Events';
    }
    &:nth-child(4) p::after {
      content: 'Cornering Events';
    }
    &:nth-child(5) p::after {
      content: 'Distraction Events';
    }
    &:last-child {
      > div {
        border-bottom: none;
      }
    }
    /* ------------------------------------------------------------ */
    /* Icon Container */
    /* ------------------------------------------------------------ */
    > span {
      align-items: center;
      background-color: $grey-light;
      border-radius: 50%;
      color: $white;
      display: flex;
      flex-shrink: 0;
      height: rem(20px);
      justify-content: center;
      margin: rem(2.5px) rem(10px) 0 0;
      width: rem(20px);
    }
    /* ------------------------------------------------------------ */
    /* Text Container */
    /* ------------------------------------------------------------ */
    > div {
      border-bottom: 1px solid $grey-light;
      display: flex;
      padding: rem(5px) 0;
      width: 100%;
      /* ------------------------------------------------------------ */
      /* Text */
      /* ------------------------------------------------------------ */
      > p {
        font-size: rem(12px);
        line-height: rem(15px);
        margin: rem(-5px) 0 rem(-3px);
        padding: rem(5px) rem(10px) rem(3px) 0;
        &::after {
          background-color: $grey-light;
          color: transparent;
          font-size: inherit;
          line-height: inherit;
        }
      }
      /* ------------------------------------------------------------ */
      /* Score */
      /* ------------------------------------------------------------ */
      > span {
        align-items: center;
        background-color: $grey-light;
        border-radius: rem(10px);
        color: $grey-dark;
        display: flex;
        flex-shrink: 0;
        height: rem(15px);
        justify-content: center;
        margin: 0 0 0 auto;
        width: rem(15px);
      }
    }
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
