@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Policy Holders Summary - Points Error Message */
/* ---------------------------------------------------------------- */
.points-balance-error {
  /* ---------------------------------------------------------------- */
  /* Header */
  /* ---------------------------------------------------------------- */
  header {
    align-items: center;
    display: flex;
    position: relative;
    /* ---------------------------------------------------------------- */
    /* Icon */
    /* ---------------------------------------------------------------- */
    svg {
      height: rem(15px);
      margin-right: rem(11px);
      order: 1;
      width: rem(14px);
    }
    /* ---------------------------------------------------------------- */
    /* Title */
    /* ---------------------------------------------------------------- */
    h2 {
      order: 2;
    }
    /* ---------------------------------------------------------------- */
    /* Border */
    /* ---------------------------------------------------------------- */
    &::after {
      background-color: $grey-light;
      bottom: 0;
      content: '';
      height: rem(1px);
      left: rem(20px);
      position: absolute;
      right: rem(20px);
      width: calc(100% - rem(40px));
    }
  }
  /* ---------------------------------------------------------------- */
  /* Content */
  /* ---------------------------------------------------------------- */
  > div {
    align-items: center;
    display: grid;
    text-align: center;
  }
  /* ---------------------------------------------------------------- */
  /* Icon */
  /* ---------------------------------------------------------------- */
  > div > svg {
    color: $grey;
    flex-shrink: 0;
    height: rem(40px);
    margin: rem(20px) auto rem(16px);
    width: rem(40px);
    * {
      fill: currentcolor;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Name */
  /* ---------------------------------------------------------------- */
  > div > div > h2 {
    color: $grey;
    font-size: rem(18px);
    font-weight: $bold;
    line-height: rem(22px);
    margin: rem(-5px) 0 rem(-4px);
    padding-bottom: rem(16px);
    text-align: center;
  }
  /* ---------------------------------------------------------------- */
  /* Message */
  /* ---------------------------------------------------------------- */
  p {
    color: $grey;
    font-size: rem(16px);
    line-height: rem(19px);
    margin: rem(-4px) 0 rem(-3px);
    padding-bottom: rem(16px);
    text-align: center;
  }
  /* ---------------------------------------------------------------- */
  /* Button */
  /* ---------------------------------------------------------------- */
  button {
    align-self: center;
    justify-self: center;
    margin-top: rem(16px);
  }
}
