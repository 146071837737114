@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Generate Report */
/* ---------------------------------------------------------------- */
.generate-report[class] {
  form {
    /* ---------------------------------------------------------------- */
    /* Question Container */
    /* ---------------------------------------------------------------- */
    > div {
      margin: 0;
      /* ---------------------------------------------------------------- */
      /* Select + Button Container */
      /* ---------------------------------------------------------------- */
      > div {
        display: flex;
        position: relative;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Select */
  /* ---------------------------------------------------------------- */
  select {
    /* ---------------------------------------------------------------- */
    /* Chevron */
    /* ---------------------------------------------------------------- */
    + svg {
      right: rem(135px);
    }
  }
  /* ---------------------------------------------------------------- */
  /* Button */
  /* ---------------------------------------------------------------- */
  button {
    margin-left: rem(10px);
    width: rem(107px);
  }
}
