@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Input Container */
/* ---------------------------------------------------------------- */
.textarea {
  /* ---------------------------------------------------------------- */
  /* Input */
  /* ---------------------------------------------------------------- */
  textarea {
    appearance: none;
    background-color: $white;
    border: rem(1px) solid $blue-pastel-dark;
    border-radius: rem(3px);
    box-sizing: border-box;
    caret-color: $blue-pastel-dark;
    color: $blue-pastel-dark;
    display: inline-block;
    font-family: $primary;
    font-weight: $regular;
    letter-spacing: normal;
    line-height: normal;
    outline: none;
    resize: none;
    vertical-align: middle;
    width: 100%;
    @include transition($duration: 0.2s, $timing: 'linear');
    /* ---------------------------------------------------------------- */
    /* Placeholder */
    /* ---------------------------------------------------------------- */
    &::placeholder {
      color: rgba($blue-pastel-dark, 0.4);
      opacity: 1;
    }
    /* ---------------------------------------------------------------- */
    /* Focus */
    /* ---------------------------------------------------------------- */
    &:focus {
      border-color: $blue;
    }
    /* ---------------------------------------------------------------- */
    /* Desktop Hover */
    /* ---------------------------------------------------------------- */
    :global(.platform-desktop) & {
      &:hover {
        border-color: $blue-dark;
        &:focus {
          border-color: $blue;
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Small */
  /* ---------------------------------------------------------------- */
  &.small {
    textarea {
      font-size: rem(14px);
      padding: rem(6px) rem(10px);
    }
  }
  /* ---------------------------------------------------------------- */
  /* Medium */
  /* ---------------------------------------------------------------- */
  &.medium {
    textarea {
      font-size: rem(16px);
      padding: rem(10px) rem(14px);
    }
  }
  /* ---------------------------------------------------------------- */
  /* Invalid */
  /* ---------------------------------------------------------------- */
  &.invalid {
    textarea {
      border-color: $red;
      :global(.platform-desktop) & {
        &:hover {
          border-color: darken($red, 15%);
        }
        &:focus {
          border-color: $blue;
        }
      }
      &:focus {
        border-color: $blue;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Read Only */
  /* ---------------------------------------------------------------- */
  &.read-only {
    textarea {
      background-color: rgba($blue-pastel-dark, 0.1);
      cursor: not-allowed;
      :global(.platform-desktop) & {
        &:hover {
          border-color: $blue-pastel-dark;
          &:focus {
            border-color: $blue;
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Disabled */
  /* ---------------------------------------------------------------- */
  &.disabled {
    textarea {
      border-color: rgba($blue-pastel-dark, 0.4);
      color: rgba($blue-pastel-dark, 0.4);
      cursor: not-allowed;
      &::placeholder {
        color: rgba($blue-pastel-dark, 0.2);
      }
      :global(.platform-desktop) & {
        &:hover {
          border-color: rgba($blue-pastel-dark, 0.4);
        }
      }
    }
  }
}
