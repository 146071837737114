/* -------------------------------------------------------------- */
/* Font Family */
/* -------------------------------------------------------------- */
$primary: 'Lato', sans-serif;
$secondary: 'Overpass', sans-serif;
/* -------------------------------------------------------------- */
/* Font Weight */
/* -------------------------------------------------------------- */
$regular: 400;
$bold: 700;
$extra-bold: 900;
/* -------------------------------------------------------------- */
/* Colours */
/* -------------------------------------------------------------- */
$blue-x-dark: #27305a;
$blue-dark: #083d91;
$blue: #0e8cf8;
$blue-pastel-dark: #5e5e72;
$blue-pastel: #d9e4ea;
$blue-pastel-light: #ecf3fc;

$red-dark: #c4132b;
$red: #e62a29;

$green-dark: #39ad6a;
$green: #4bca81;
$green-light: #3ae180;
$green-pastel-light: #f4f8fa;

$yellow: #f9cb34;

$violet: #b430fa;
/* -------------------------------------------------------------- */
/* Shades */
/* -------------------------------------------------------------- */
$black: #000;
$grey-x-dark: #1c1c1c;
$grey-dark: #353535;
$grey: #83929a;
$grey-light: #d6d6d6;
$grey-x-light: #b3b3c4;
$white: #fff;
/* -------------------------------------------------------------- */
/* MISC */
/* -------------------------------------------------------------- */
$googlemap-background: #e9e6e0;
$mapbox-background: #e9e6e0;
