@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ------------------------------------------------------------ */
/* Events Summary Item */
/* ------------------------------------------------------------ */
.events-summary-item {
  display: flex;
  @include no-user-select;
  /* ------------------------------------------------------------ */
  /* Icon Container */
  /* ------------------------------------------------------------ */
  > span {
    align-items: center;
    background-color: $red;
    border-radius: 50%;
    color: $white;
    display: flex;
    flex-shrink: 0;
    height: rem(20px);
    justify-content: center;
    margin: rem(2.5px) rem(10px) 0 0;
    width: rem(20px);
    /* ------------------------------------------------------------ */
    /* Icon  */
    /* ------------------------------------------------------------ */
    svg {
      flex-shrink: 0;
      height: rem(13px);
      width: rem(13px);
      &[aria-label='Braking Events'] {
        height: rem(16px);
        width: rem(16px);
      }
      &[aria-label='Over Speed Events'] {
        height: rem(14px);
        width: rem(14px);
      }
      &[aria-label='Distracted Driving Events'],
      &[aria-label='Overall'] {
        height: rem(12px);
        width: rem(12px);
      }
      * {
        fill: currentcolor;
      }
    }
  }
  /* ------------------------------------------------------------ */
  /* Text Container */
  /* ------------------------------------------------------------ */
  > div {
    border-bottom: 1px solid $grey-light;
    display: flex;
    padding: rem(5px) 0;
    width: 100%;
    @at-root {
      :last-child#{&} {
        border-bottom: none;
      }
    }
    /* ------------------------------------------------------------ */
    /* Text */
    /* ------------------------------------------------------------ */
    > p {
      font-size: rem(12px);
      line-height: rem(15px);
      margin: rem(-5px) 0 rem(-3px);
      padding: rem(5px) rem(10px) rem(3px) 0;
    }
    /* ------------------------------------------------------------ */
    /* Score */
    /* ------------------------------------------------------------ */
    > span {
      align-items: center;
      background-color: $green-pastel-light;
      border-radius: rem(10px);
      color: $grey-dark;
      display: flex;
      flex-shrink: 0;
      font-size: rem(12px);
      height: rem(15px);
      justify-content: center;
      line-height: rem(15px);
      margin: 0 0 0 auto;
      padding: 0 rem(4px);
    }
  }
  /* ------------------------------------------------------------ */
  /* Active (Value > 0) */
  /* ------------------------------------------------------------ */
  &.active {
    > div > span {
      background-color: $red;
      color: $white;
    }
  }
}
