@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Trip List Summary Skeleton */
/* ---------------------------------------------------------------- */
.skeleton {
  /* ---------------------------------------------------------------- */
  /* Item */
  /* ---------------------------------------------------------------- */
  > div > div {
    border: rem(1px) solid $grey-light;
    border-radius: rem(3px);
    box-sizing: border-box;
    overflow: hidden;
    padding: rem(15px) rem(56px) rem(15px) rem(10px);
    position: relative;
    width: 100%;
    &::after {
      @include skeleton-shimmer;
      @include skeleton-shimmer-color($color: $white);
    }
    /* ---------------------------------------------------------------- */
    /* Date Time */
    /* ---------------------------------------------------------------- */
    .date-time {
      display: flex;
      /* ---------------------------------------------------------------- */
      /* Text */
      /* ---------------------------------------------------------------- */
      > span {
        font-size: rem(12px);
        line-height: rem(15px);
        margin: rem(-3px) 0;
        padding-bottom: rem(10px);
        white-space: pre;
        &::after {
          background-color: $grey-light;
          color: transparent;
          content: '24/02/2022, 11:30 - 12:30';
          font-size: inherit;
          line-height: inherit;
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Duration / Distance */
    /* ---------------------------------------------------------------- */
    .duration {
      display: flex;
      /* ---------------------------------------------------------------- */
      /* Duration / Distance Containers */
      /* ---------------------------------------------------------------- */
      > span {
        align-items: center;
        display: flex;
        > span {
          /* ---------------------------------------------------------------- */
          /* Duration */
          /* ---------------------------------------------------------------- */
          &:first-child {
            background-color: $grey-light;
            border-radius: 50%;
            height: rem(12px);
            margin: 0 rem(8px) 0 0;
            width: rem(12px);
          }
          /* ---------------------------------------------------------------- */
          /* Distance */
          /* ---------------------------------------------------------------- */
          &:last-child {
            font-size: rem(10px);
            line-height: rem(12px);
            margin: 0 rem(20px) 0 0;
            &::after {
              background-color: $grey-light;
              color: transparent;
              content: '75 min';
              font-size: inherit;
              line-height: inherit;
            }
          }
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Overall */
    /* ---------------------------------------------------------------- */
    .overall {
      background-color: $grey-light;
      border-radius: 50%;
      font-size: rem(16px);
      font-weight: $bold;
      height: rem(36px);
      line-height: rem(19px);
      margin-top: rem(-18px);
      position: absolute;
      right: rem(10px);
      top: 50%;
      width: rem(36px);
    }
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
