@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Policy Number Form */
/* ---------------------------------------------------------------- */
.policy-number-form {
  /* ---------------------------------------------------------------- */
  /* Question */
  /* ---------------------------------------------------------------- */
  > div {
    /* ---------------------------------------------------------------- */
    /* Label */
    /* ---------------------------------------------------------------- */
    > label[for] {
      color: $grey-dark;
      font-size: rem(18px);
      font-weight: $bold;
      line-height: rem(22px);
      margin: rem(-5px) 0 rem(20px);
    }
    /* ---------------------------------------------------------------- */
    /* Input Container */
    /* ---------------------------------------------------------------- */
    > div {
      /* ---------------------------------------------------------------- */
      /* Search Button */
      /* ---------------------------------------------------------------- */
      > button {
        align-self: flex-start;
        margin-left: rem(12px);
        width: rem(80px);
      }
    }
  }
}
