@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Card */
/* ---------------------------------------------------------------- */
.card {
  background-color: $white;
  border-radius: rem(3px);
  box-shadow: 0 rem(3px) rem(6px) rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  + .card {
    margin-top: rem(16px);
  }
  /* ---------------------------------------------------------------- */
  /* Header */
  /* ---------------------------------------------------------------- */
  header {
    /* ---------------------------------------------------------------- */
    /* Title */
    /* ---------------------------------------------------------------- */
    > h2 {
      color: $grey-dark;
      font-weight: $bold;
      @include no-user-select;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Padding Large */
  /* ---------------------------------------------------------------- */
  &.padding-large {
    header {
      padding: rem(30px);
      + div {
        padding-top: 0;
      }
    }
    > div {
      padding: rem(30px);
    }
    &.scrollable {
      > div {
        padding: 0 rem(29px) rem(29px) rem(30px);
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Padding Small */
  /* ---------------------------------------------------------------- */
  &.padding-small {
    header {
      padding: rem(20px);
      + div {
        padding-top: 0;
      }
    }
    > div {
      padding: rem(20px);
    }
    &.scrollable {
      > div {
        padding: 0 rem(19px) rem(19px) rem(20px);
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Font Size Large */
  /* ---------------------------------------------------------------- */
  &.font-size-large {
    header {
      > h2 {
        font-size: rem(18px);
        line-height: rem(22px);
        margin: rem(-5px) 0 rem(-4px);
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Font Size Small */
  /* ---------------------------------------------------------------- */
  &.font-size-small {
    header {
      > h2 {
        font-size: rem(14px);
        line-height: rem(17px);
        margin: rem(-3px) 0;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Scrollable */
  /* ---------------------------------------------------------------- */
  &.scrollable {
    height: 100%;
    max-height: calc(100% - rem(24px));
    > div {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      margin-bottom: rem(1px);
      overflow-x: hidden;
      overflow-y: auto;
      scrollbar-color: $red-dark transparent;
      scrollbar-width: thin;
      width: calc(100% - rem(1px));
      :global(.browser-firefox.platform-mac) &,
      :global(.platform-ios:not(.platform-mac)) & {
        padding-right: rem(20px);
        width: 100%;
      }
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: rem(8px);
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
        border-radius: rem(8px);
        box-shadow: inset 0 0 rem(6px) rgba(0, 0, 0, 0);
      }
      &::-webkit-scrollbar-thumb {
        background-color: $red-dark;
        border-radius: rem(8px);
      }
    }
  }
}
