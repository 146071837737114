@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ------------------------------------------------------------ */
/* Main */
/* ------------------------------------------------------------ */
.main {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0;
  width: calc(100% - rem(60px));
  @include transition($props: 'padding, width', $duration: 0.35s);
  .config-loading {
    color: $blue-pastel-dark;
    height: rem(60px);
    left: 50%;
    margin: 0 0 0 rem(-60px);
    position: relative;
    top: 50%;
    transform: translate(50%, -50%);
    width: rem(60px);
    z-index: 1;
    * {
      fill: currentcolor;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Menu Open */
  /* ---------------------------------------------------------------- */
  :global(.menu-open) & {
    padding: 0 0 0 rem(28px);
    width: calc(100% - rem(292px));
  }
}
