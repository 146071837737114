@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* -------------------------------------------------------------- */
/* Policy Holders Summary - History Rewards */
/* -------------------------------------------------------------- */
.history-rewards-item {
  border-bottom: rem(1px) solid $blue-pastel;
  color: $grey-dark;
  display: flex;
  flex-direction: column;
  position: relative;
  /* -------------------------------------------------------------- */
  /* Container */
  /* -------------------------------------------------------------- */
  > div:first-child {
    align-items: center;
    display: flex;
    padding: rem(12px) rem(10px);
  }
  /* -------------------------------------------------------------- */
  /* Icon */
  /* -------------------------------------------------------------- */
  > div > div {
    align-items: center;
    display: flex;
    height: rem(24px);
    justify-content: center;
    width: rem(24px);
    > svg * {
      fill: currentcolor;
    }
  }
  /* -------------------------------------------------------------- */
  /* Date of Reward + Description */
  /* -------------------------------------------------------------- */
  > div p {
    display: grid;
    grid-gap: rem(4px);
    margin-right: auto;
    padding: 0 rem(20px);
    /* -------------------------------------------------------------- */
    /* Date of Reward */
    /* -------------------------------------------------------------- */
    strong {
      font-size: rem(8px);
      font-weight: $extra-bold;
      line-height: rem(10px);
      margin: rem(-1px) 0 rem(-2px);
      text-transform: uppercase;
    }
    /* -------------------------------------------------------------- */
    /* Description */
    /* -------------------------------------------------------------- */
    span {
      font-size: rem(12px);
      line-height: rem(14px);
      margin: rem(-2px) 0;
    }
  }
  /* -------------------------------------------------------------- */
  /* Button - Reason Toggle */
  /* -------------------------------------------------------------- */
  button {
    align-items: center;
    background-color: transparent;
    border: rem(1px) solid $blue-pastel;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: rem(24px);
    justify-content: center;
    position: absolute;
    right: rem(80px);
    width: rem(24px);
    @include transition(
      $props: 'background-color, border-color',
      $duration: '0.35s'
    );
    /* -------------------------------------------------------------- */
    /* Icon */
    /* -------------------------------------------------------------- */
    > svg {
      color: $blue;
      height: rem(10px);
      opacity: 1;
      position: absolute;
      transform: translateY(rem(1px));
      width: rem(12px);
      @include transition($props: 'opacity, color', $duration: '0.35s');
      &:nth-child(2) {
        opacity: 0;
      }
      * {
        fill: currentcolor;
      }
    }
    /* -------------------------------------------------------------- */
    /* Focus */
    /* -------------------------------------------------------------- */
    &:focus {
      background-color: $blue-pastel;
      border-color: $blue;
      > svg:nth-child(1) {
        opacity: 0;
      }
      > svg:nth-child(2) {
        opacity: 1;
      }
    }
    /* -------------------------------------------------------------- */
    /* Desktop Hover */
    /* -------------------------------------------------------------- */
    :global(.platform-desktop) & {
      &:hover {
        background-color: $blue-pastel;
        > svg:nth-child(1) {
          opacity: 0;
        }
        > svg:nth-child(2) {
          opacity: 1;
        }
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Points */
  /* -------------------------------------------------------------- */
  > div > span:last-child {
    display: grid;
    grid-gap: rem(4px);
    text-align: right;
    /* -------------------------------------------------------------- */
    /* Total */
    /* -------------------------------------------------------------- */
    > span:first-child {
      font-size: rem(8px);
      font-weight: $extra-bold;
      line-height: rem(10px);
      margin: rem(-1px) 0 rem(-2px);
      text-transform: uppercase;
    }
    /* -------------------------------------------------------------- */
    /* Difference */
    /* -------------------------------------------------------------- */
    > span:last-child {
      font-size: rem(12px);
      line-height: rem(14px);
      margin: rem(-2px) 0;
    }
  }
  /* -------------------------------------------------------------- */
  /* Reason */
  /* -------------------------------------------------------------- */
  .reason {
    height: 0;
    overflow: hidden;
    @include transition($props: 'height, margin', $duration: '0.35s');
    > p {
      background-color: $green-pastel-light;
      box-sizing: border-box;
      display: block;
      font-size: rem(10px);
      line-height: rem(12px);
      margin: 0 rem(10px) rem(12px);
      padding: rem(11px) rem(12px) rem(10px);
    }
  }
  /* -------------------------------------------------------------- */
  /* Show Reason */
  /* -------------------------------------------------------------- */
  &.show-reason {
    button {
      background-color: $blue;
      border-color: $blue;
      > svg:nth-child(1) {
        opacity: 0;
      }
      > svg:nth-child(2) {
        color: $white;
        opacity: 1;
      }
      &:focus {
        background-color: rgba($blue, 0.75);
        border-color: $blue-pastel;
        > svg:nth-child(1) {
          opacity: 0;
        }
        > svg:nth-child(2) {
          opacity: 1;
        }
      }
      :global(.platform-desktop) & {
        &:hover {
          background-color: rgba($blue, 0.75);
          > svg:nth-child(1) {
            opacity: 0;
          }
          > svg:nth-child(2) {
            opacity: 1;
          }
        }
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Added */
  /* -------------------------------------------------------------- */
  &.added {
    > div > div > svg,
    > div > span:last-child > span:last-child {
      color: $green-dark;
    }
  }
  /* -------------------------------------------------------------- */
  /* Deducted */
  /* -------------------------------------------------------------- */
  &.removed {
    > div > div > svg,
    > div > span:last-child > span:last-child {
      color: $red;
    }
  }
  /* -------------------------------------------------------------- */
  /* Expired */
  /* -------------------------------------------------------------- */
  &.expired {
    > div p,
    > div > div > svg,
    > div > span:last-child > span:last-child {
      color: $grey-x-light;
    }
  }
}
