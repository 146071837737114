@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Policy Holders Summary - History Messages Skeleton */
/* ---------------------------------------------------------------- */
.history-messages-skeleton {
  display: flex;
  flex-direction: column;
  /* ---------------------------------------------------------------- */
  /* History Messages Item */
  /* ---------------------------------------------------------------- */
  li {
    border-bottom: rem(1px) solid $blue-pastel;
    box-sizing: border-box;
    display: flex;
    overflow: hidden;
    padding: rem(12px) rem(10px) rem(10px);
    position: relative;
    text-align: left;
    width: 100%;
    &::after {
      @include skeleton-shimmer;
      @include skeleton-shimmer-color($color: $white);
    }
    /* -------------------------------------------------------------- */
    /* Message Type Icon */
    /* -------------------------------------------------------------- */
    > div {
      align-items: center;
      background-color: $grey-light;
      border-radius: 50%;
      display: flex;
      height: rem(24px);
      justify-content: center;
      position: relative;
      width: rem(24px);
    }
    /* -------------------------------------------------------------- */
    /* Type + Description */
    /* -------------------------------------------------------------- */
    p {
      display: grid;
      grid-gap: rem(4px);
      margin-right: auto;
      padding: 0 rem(20px);
      /* -------------------------------------------------------------- */
      /* Type */
      /* -------------------------------------------------------------- */
      strong {
        font-size: rem(8px);
        font-weight: $extra-bold;
        line-height: rem(10px);
        margin: rem(-1px) 0 rem(-2px);
        text-transform: uppercase;
        &::after {
          background-color: $grey-light;
          color: transparent;
          content: 'EMAIL';
          font-size: inherit;
          line-height: inherit;
        }
      }
      /* -------------------------------------------------------------- */
      /* Description */
      /* -------------------------------------------------------------- */
      > span {
        height: rem(15px);
        line-height: rem(14px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        will-change: height;
        @include transition($props: 'height', $duration: '0.35s');
        > span {
          font-size: rem(12px);
          line-height: rem(14px);
          &::after {
            background-color: $grey-light;
            color: transparent;
            content: 'You have a red week!';
            font-size: inherit;
            line-height: inherit;
          }
        }
      }
    }
    /* -------------------------------------------------------------- */
    /* Date */
    /* -------------------------------------------------------------- */
    > span {
      display: flex;
      flex-direction: column;
      margin-left: rem(30px);
      margin-right: rem(30px);
      position: relative;
      text-align: right;
      top: rem(1.5px);
      /* -------------------------------------------------------------- */
      /* Date + Timestamp */
      /* -------------------------------------------------------------- */
      > span {
        font-size: rem(9px);
        line-height: rem(11px);
        margin: rem(-1px) 0 rem(-2px);
        white-space: nowrap;
        &::after {
          background-color: $grey-light;
          color: transparent;
          font-size: inherit;
          line-height: inherit;
        }
        &:first-child::after {
          content: '27 Jan 23';
        }
        &:last-child {
          margin-top: rem(2.5px);
          &::after {
            content: '11:59am';
          }
        }
      }
    }
    /* -------------------------------------------------------------- */
    /* Icon */
    /* -------------------------------------------------------------- */
    > span:last-child {
      background-color: $grey-light;
      border-radius: 50%;
      height: rem(12px);
      margin: 0;
      position: absolute;
      right: rem(10px);
      top: rem(18px);
      width: rem(12px);
    }
  }
  /* ---------------------------------------------------------------- */
  /* Footer */
  /* ---------------------------------------------------------------- */
  footer {
    align-items: center;
    display: flex;
    font-size: rem(12px);
    height: rem(34px);
    justify-content: flex-end;
    line-height: 1;
    margin-bottom: rem(-10px);
    overflow: hidden;
    padding: 0 rem(10px);
    position: relative;
    &::after {
      @include skeleton-shimmer;
      @include skeleton-shimmer-color($color: $white);
    }
    strong {
      font-weight: 700;
    }
    /* ---------------------------------------------------------------- */
    /* Pagination */
    /* ---------------------------------------------------------------- */
    > div:first-child {
      &::after {
        background-color: $grey-light;
        color: transparent;
        content: ' of 83';
      }
      strong {
        &::after {
          background-color: $grey-light;
          color: transparent;
          content: '1 - 10';
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Previous Page Button */
    /* ---------------------------------------------------------------- */
    div:nth-of-type(2) {
      margin-left: rem(12px);
    }
    /* ---------------------------------------------------------------- */
    /* Previous Page Button */
    /* ---------------------------------------------------------------- */
    div:nth-of-type(3) {
      margin-left: rem(6px);
      margin-right: rem(-8px);
    }
    /* ---------------------------------------------------------------- */
    /* Button */
    /* ---------------------------------------------------------------- */
    > div:not(:first-child) {
      align-items: center;
      cursor: wait;
      display: flex;
      height: rem(34px);
      justify-content: center;
      position: relative;
      width: rem(20px);
      /* ---------------------------------------------------------------- */
      /* Button Icon */
      /* ---------------------------------------------------------------- */
      > span {
        background-color: $grey-light;
        height: rem(14.5px);
        width: rem(5px);
      }
    }
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
