@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Drawer Template - Additional Detail Field */
/* ---------------------------------------------------------------- */
.additional-detail-field {
  align-items: center;
  border-bottom: rem(1px) solid $blue-pastel;
  display: flex;
  height: rem(30px);
  padding: 0 rem(10px);
  &:focus {
    outline: none;
  }
  /* ---------------------------------------------------------------- */
  /* Columns */
  /* ---------------------------------------------------------------- */
  > div {
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  /* ---------------------------------------------------------------- */
  /* Col 1 - Name */
  /* ---------------------------------------------------------------- */
  > div:nth-child(1) {
    flex-shrink: 0;
    padding-right: rem(5px);
    width: rem(110px);
  }
  /* ---------------------------------------------------------------- */
  /* Col 2 - Value */
  /* ---------------------------------------------------------------- */
  > div:nth-child(2) {
    flex-grow: 1;
    padding-left: rem(5px);
  }
  /* ---------------------------------------------------------------- */
  /* Edit Button */
  /* ---------------------------------------------------------------- */
  > button {
    cursor: pointer;
    flex-shrink: 0;
    height: rem(14px);
    margin-left: rem(10px);
    width: rem(14px);
    /* ---------------------------------------------------------------- */
    /* Disabled */
    /* ---------------------------------------------------------------- */
    &:disabled {
      cursor: not-allowed;
      svg {
        color: $grey;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Focus */
    /* ---------------------------------------------------------------- */
    &:focus {
      border-radius: 50%;
      outline: rem(2px) solid $blue;
      outline-offset: rem(3px);
    }
    /* ---------------------------------------------------------------- */
    /* Icon */
    /* ---------------------------------------------------------------- */
    svg {
      color: $blue;
      * {
        fill: currentcolor;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Saving */
  /* ---------------------------------------------------------------- */
  .saving {
    align-items: center;
    display: flex;
    /* ---------------------------------------------------------------- */
    /* Spinner Container */
    /* ---------------------------------------------------------------- */
    > span {
      height: rem(12px);
      margin-right: rem(10px);
      width: rem(12px);
    }
  }
  /* ---------------------------------------------------------------- */
  /* Sending */
  /* ---------------------------------------------------------------- */
  &.sending {
    cursor: wait;
  }
}
