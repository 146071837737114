@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Fieldset */
/* ---------------------------------------------------------------- */
.fieldset {
  @include no-user-select;
  /* ---------------------------------------------------------------- */
  /* Legend */
  /* ---------------------------------------------------------------- */
  > legend {
    margin: 0 0 rem(16px);
    width: 100%;
    /* ---------------------------------------------------------------- */
    /* Title */
    /* ---------------------------------------------------------------- */
    h2 {
      color: $grey-dark;
      font-weight: $bold;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Small */
  /* ---------------------------------------------------------------- */
  &.small {
    > legend h2 {
      font-size: rem(14px);
      line-height: rem(17px);
      margin: rem(-3px) 0;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Large */
  /* ---------------------------------------------------------------- */
  &.large {
    > legend h2 {
      font-size: rem(18px);
      line-height: rem(22px);
      margin: rem(-5px) 0 rem(-4px);
    }
  }
}
