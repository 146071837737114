@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* -------------------------------------------------------------- */
/* Policy Holders Summary - Add / Remove Points */
/* -------------------------------------------------------------- */
header.add-remove-points {
  align-items: center;
  display: flex;
  flex-direction: row;
  position: relative;
  /* ---------------------------------------------------------------- */
  /* Border */
  /* ---------------------------------------------------------------- */
  &::after {
    background-color: $blue-pastel;
    bottom: 0;
    content: '';
    height: rem(1px);
    left: rem(20px);
    position: absolute;
    right: rem(20px);
    width: calc(100% - rem(40px));
  }
  /* -------------------------------------------------------------- */
  /* Title */
  /* -------------------------------------------------------------- */
  > h2:not(:last-child) {
    padding: 0 rem(10px) 0 0;
  }
  /* ---------------------------------------------------------------- */
  /* Close Button */
  /* ---------------------------------------------------------------- */
  button {
    cursor: pointer;
    height: rem(13px);
    margin-left: auto;
    position: relative;
    width: rem(13px);
    /* ---------------------------------------------------------------- */
    /* Disabled */
    /* ---------------------------------------------------------------- */
    &:disabled {
      cursor: not-allowed;
      svg {
        color: $grey;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Focus */
    /* ---------------------------------------------------------------- */
    &:focus {
      border-radius: 50%;
      outline: rem(2px) solid $blue;
      outline-offset: rem(5px);
    }
  }
  /* ---------------------------------------------------------------- */
  /* Button Icon */
  /* ---------------------------------------------------------------- */
  svg {
    color: $blue;
    height: rem(13px);
    width: rem(13px);
    * {
      fill: currentcolor;
    }
  }
  /* -------------------------------------------------------------- */
  /* Content */
  /* -------------------------------------------------------------- */
  + div {
    padding: rem(20px);
    /* -------------------------------------------------------------- */
    /* Button Container */
    /* -------------------------------------------------------------- */
    .buttons {
      margin: 0 0 rem(20px);
      /* -------------------------------------------------------------- */
      /* Button */
      /* -------------------------------------------------------------- */
      button {
        align-items: center;
        border: rem(1px) solid $grey-light;
        border-radius: rem(18px);
        color: $blue-x-dark;
        cursor: pointer;
        display: inline-flex;
        font-size: rem(12px);
        height: rem(24px);
        line-height: rem(13px);
        padding: 0 rem(10px);
        position: relative;
        text-decoration: underline;
        text-decoration-color: transparent;
        text-underline-offset: rem(1px);
        @include no-user-select;
        @include transition(
          $props: 'background-color, border-color, color',
          $duration: 0.35s
        );
        &:not(:last-child) {
          margin-right: rem(10px);
        }
        /* -------------------------------------------------------------- */
        /* Focus */
        /* -------------------------------------------------------------- */
        &:focus {
          background-color: $blue-pastel-light;
          text-decoration-color: $blue-x-dark;
        }
        /* -------------------------------------------------------------- */
        /* Hover */
        /* -------------------------------------------------------------- */
        :global(.platform-desktop) & {
          &:not(.selected):not(:disabled):hover {
            background-color: $blue-pastel-light;
          }
        }
        /* -------------------------------------------------------------- */
        /* Selected */
        /* -------------------------------------------------------------- */
        &.selected {
          background: $blue;
          border-color: $blue;
          color: $white;
          &:focus {
            text-decoration-color: $white;
          }
        }
        /* -------------------------------------------------------------- */
        /* Disabled */
        /* -------------------------------------------------------------- */
        &:disabled[class] {
          background-color: $blue-pastel;
          border-color: $blue-pastel;
          color: $blue-pastel-dark;
          cursor: not-allowed;
        }
      }
    }
    /* -------------------------------------------------------------- */
    /* Warning */
    /* -------------------------------------------------------------- */
    .warning {
      background-color: $blue-pastel;
      border-radius: rem(3px);
      box-sizing: border-box;
      margin: 0 0 rem(20px);
      overflow: hidden;
      will-change: height;
      @include transition($duration: 0.5s);
      > div {
        display: flex;
        padding: rem(12px);
      }
      /* -------------------------------------------------------------- */
      /* Icon */
      /* -------------------------------------------------------------- */
      svg {
        flex-shrink: 0;
        height: rem(12px);
        margin-right: rem(12px);
        position: relative;
        width: rem(12px);
      }
      /* -------------------------------------------------------------- */
      /* Text */
      /* -------------------------------------------------------------- */
      p {
        font-size: rem(12px);
        line-height: rem(14px);
        margin: rem(-2px) 0;
      }
    }
    /* -------------------------------------------------------------- */
    /* Form */
    /* -------------------------------------------------------------- */
    form {
      > *:last-child {
        margin-bottom: 0;
      }
    }
  }
}
