@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Container */
/* ---------------------------------------------------------------- */
.container {
  display: flex;
  height: 100%;
  overflow: hidden;
}
/* ---------------------------------------------------------------- */
/* Row */
/* ---------------------------------------------------------------- */
.row {
  display: flex;
  margin-left: rem(-8px);
  margin-right: rem(-8px);
  > .column {
    padding-top: rem(16px);
    &:first-child {
      padding-left: rem(8px);
    }
    &:last-child {
      padding-right: rem(8px);
    }
  }
}
/* ---------------------------------------------------------------- */
/* Column */
/* ---------------------------------------------------------------- */
.column {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 0 rem(8px);
  padding-top: rem(28px);
  width: 100%;
  &:first-child {
    padding-left: rem(28px);
  }
  &:last-child {
    padding-right: rem(28px);
    > .row:first-child {
      > .column {
        padding-top: 0;
      }
    }
  }
  &.scrollable:last-child {
    width: calc(100% - rem(1px));
  }
  &.w25 {
    width: 25%;
    &.scrollable:last-child {
      width: calc(25% - rem(1px));
    }
  }
  &.w50 {
    width: 50%;
    &.scrollable:last-child {
      width: calc(50% - rem(1px));
    }
  }
  &.w100 {
    width: 100%;
    &.scrollable:last-child {
      width: calc(100% - rem(1px));
    }
  }
}
/* ---------------------------------------------------------------- */
/* Scrollable */
/* ---------------------------------------------------------------- */
.scrollable {
  max-width: calc(100% - rem(1px));
  scrollbar-color: $red-dark transparent;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: rem(8px);
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: rem(8px);
    box-shadow: inset 0 0 rem(6px) rgba(0, 0, 0, 0);
  }
  &::-webkit-scrollbar-thumb {
    background-color: $red-dark;
    border-radius: rem(8px);
  }
  // Only allow scroll if column is touching right-hand side of window
  &:last-child {
    overflow-x: hidden;
    overflow-y: scroll;
    padding-right: rem(20px);
  }
  :global(.browser-firefox.platform-mac) &,
  :global(.platform-ios:not(.platform-mac)) & {
    &:last-child {
      padding-right: rem(28px);
    }
  }
  :global(.platform-windows.browser-firefox) & {
    &:last-child {
      padding-right: rem(19px);
    }
  }
  // Prevents child elements box-shadow from being clipped
  > *:last-child {
    margin-bottom: rem(24px);
  }
}
