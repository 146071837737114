@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Google Map */
/* ---------------------------------------------------------------- */
.google-map[class] {
  overflow: hidden;
  /* ---------------------------------------------------------------- */
  /* Container */
  /* ---------------------------------------------------------------- */
  > div {
    padding: 0;
    position: relative;
  }
  > div > div {
    align-items: center;
    background-color: $googlemap-background;
    display: flex;
    flex-direction: column;
    height: rem(380px);
    justify-content: center;
    text-align: center;
    width: 100%;
  }
  /* ---------------------------------------------------------------- */
  /* Remove Duplicate Keyboard Shortcut */
  /* ---------------------------------------------------------------- */
  :global(.gm-style) > div > [aria-label='Keyboard shortcuts'] {
    display: none !important;
  }
  /* ---------------------------------------------------------------- */
  /* Map Focus */
  /* ---------------------------------------------------------------- */
  [aria-label='Map']:focus + iframe + div {
    border: rem(1px) solid $red !important;
    border-radius: rem(3px);
  }
  /* ---------------------------------------------------------------- */
  /* Street Map / Satellite Focus */
  /* ---------------------------------------------------------------- */
  [aria-label='Show street map']:focus,
  [aria-label='Show satellite imagery']:focus {
    background-color: $blue-pastel-light !important;
    text-decoration: underline;
  }
  /* ---------------------------------------------------------------- */
  /* Toggle Fullscreen Focus */
  /* ---------------------------------------------------------------- */
  [aria-label='Toggle fullscreen view']:focus {
    background-color: $blue-pastel-light !important;
  }
  /* ---------------------------------------------------------------- */
  /* Zoom In / Out Focus */
  /* ---------------------------------------------------------------- */
  [aria-label='Zoom in']:focus,
  [aria-label='Zoom out']:focus {
    background-color: $blue-pastel-light !important;
  }
  /* ---------------------------------------------------------------- */
  /* Google Logo */
  /* ---------------------------------------------------------------- */
  [title='Open this area in Google Maps (opens a new window)']:focus {
    display: block !important;
    outline: rem(1px) solid $red;
    outline-offset: rem(-2.5px);
  }
  /* ---------------------------------------------------------------- */
  /* Keyboard Shortcuts, Terms of Use, Report Focus */
  /* ---------------------------------------------------------------- */
  [aria-label='Keyboard shortcuts']:focus,
  [aria-label='Map Data']:focus,
  a:focus {
    color: $red !important;
  }
  /* ---------------------------------------------------------------- */
  /* Marker */
  /* ---------------------------------------------------------------- */
  :global(.google-marker),
  :global(.google-marker-active) {
    background-repeat: no-repeat;
    background-size: contain;
    box-sizing: border-box;
    display: flex;
    flex-shrink: 0;
    font-size: rem(14px) !important;
    height: rem(35px);
    justify-content: center;
    padding-top: rem(6px);
    width: rem(28px);
  }
  :global(.google-marker) {
    /* Converted to base64. Image: modules/themes/images/icons/events/map/map-marker.svg */
    background-image: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTAwIDEyNSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsPSIjNUE1QTVBIiBkPSJNMTAwLDUyLjQ5MTNDMTAwLDgxLjQ3OTUsNTAsMTI1LDUwLDEyNVMwLDgxLjQ3OTUsMCw1Mi40OTEzUzIyLjM5MzIsMCw1MCwwUzEwMCwyMy41MDMzLDEwMCw1Mi40OTEzeiIgLz48L3N2Zz4=');
  }
  :global(.google-marker-active) {
    /* Converted to base64. Image: modules/themes/images/icons/events/map/map-marker-active.svg */
    background-image: url('data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgdmlld0JveD0iMCAwIDEwMCAxMjUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+Cgk8cGF0aCBmaWxsPSIjRTYyRDI5IiBkPSJNMTAwLDUyLjQ5MTNDMTAwLDgxLjQ3OTUsNTAsMTI1LDUwLDEyNVMwLDgxLjQ3OTUsMCw1Mi40OTEzUzIyLjM5MzIsMCw1MCwwUzEwMCwyMy41MDMzLDEwMCw1Mi40OTEzeiIgLz4KPC9zdmc+');
  }
  :global(.google-marker-hover) {
    /* Converted to base64. Image: modules/themes/images/icons/events/map/map-marker-hover.svg */
    background-image: url("data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgdmlld0JveD0iMCAwIDEwMCAxMjUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPHBhdGggZD0iTTEwMCw1Mi40OWMwLDI4Ljk5LTUwLDcyLjUxLTUwLDcyLjUxLDAsMC01MC00My41Mi01MC03Mi41MVMyMi4zOSwwLDUwLDBzNTAsMjMuNSw1MCw1Mi40OVoiIHN0eWxlPSJmaWxsOiAjMGU4Y2Y4OyIvPgo8L3N2Zz4=");
  }
  /* ---------------------------------------------------------------- */
  /* Loading */
  /* ---------------------------------------------------------------- */
  &.loading {
    /* ---------------------------------------------------------------- */
    /* Container */
    /* ---------------------------------------------------------------- */
    > div {
      align-items: center;
      display: flex;
      justify-content: center;
    }
    /* ---------------------------------------------------------------- */
    /* Spinner */
    /* ---------------------------------------------------------------- */
    svg {
      color: $blue-pastel-dark;
      flex-shrink: 0;
      height: rem(64px);
      position: absolute;
      width: rem(64px);
      z-index: 1;
      * {
        fill: currentcolor;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Error */
  /* ---------------------------------------------------------------- */
  &.error {
    /* ---------------------------------------------------------------- */
    /* Container */
    /* ---------------------------------------------------------------- */
    > div {
      align-items: center;
      display: flex;
      justify-content: center;
    }
    > div > div {
      background-color: $white;
    }
    /* ---------------------------------------------------------------- */
    /* Icon */
    /* ---------------------------------------------------------------- */
    svg {
      color: $grey;
      flex-shrink: 0;
      height: rem(64px);
      margin-bottom: rem(16px);
      width: rem(64px);
      * {
        fill: currentcolor;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Name */
    /* ---------------------------------------------------------------- */
    h2 {
      color: $grey;
      font-size: rem(18px);
      font-weight: $bold;
      line-height: rem(2.2px);
      margin: rem(-5px) 0 rem(-4px);
      &:not(:last-child) {
        padding-bottom: rem(16px);
      }
    }
    /* ---------------------------------------------------------------- */
    /* Message */
    /* ---------------------------------------------------------------- */
    p {
      color: $grey;
      font-size: rem(16px);
      line-height: rem(19px);
      margin: rem(-4px) 0 rem(-3px);
    }
  }
}
