@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Debug  */
/* ---------------------------------------------------------------- */
.debug {
  .debug-container[class] {
    width: rem(400px);
    + div[class]:last-child {
      flex-grow: 1;
      margin-right: rem(8px);
      width: calc(100% - rem(400px));
    }
  }
  /* ---------------------------------------------------------------- */
  /* Buttons */
  /* ---------------------------------------------------------------- */
  .buttons,
  .hyperlinks {
    > div > div {
      margin: rem(-10px);
      > button,
      > a {
        margin: rem(10px);
      }
      > a:first-child {
        display: inline-block;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Form */
  /* ---------------------------------------------------------------- */
  .debug form:not(:last-child) {
    button[type='submit'] {
      margin-bottom: rem(20px);
    }
  }
  /* ---------------------------------------------------------------- */
  /* Text */
  /* ---------------------------------------------------------------- */
  section {
    p {
      font-size: rem(18px);
      line-height: rem(26px);
      margin: rem(-7px) 0 rem(-6px);
      &:not(:last-child) {
        padding-bottom: rem(20px);
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Submit Buttons */
  /* ---------------------------------------------------------------- */
  button[type='submit'] {
    margin-bottom: rem(20px);
  }
}
