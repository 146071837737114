@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Input Container */
/* ---------------------------------------------------------------- */
.input-number {
  align-items: center;
  display: grid;
  grid-auto-flow: column;
  grid-gap: rem(12px);
  justify-content: flex-start;
  overflow: hidden;
  position: relative;
  /* ---------------------------------------------------------------- */
  /* Buttons */
  /* ---------------------------------------------------------------- */
  button {
    align-items: center;
    animation-duration: 0.1s;
    animation-timing-function: ease;
    border: rem(1px) solid $blue;
    border-radius: rem(3px);
    cursor: pointer;
    display: flex;
    flex-shrink: 0;
    height: rem(12px);
    justify-content: center;
    width: rem(12px);
    @include transition(
      $mob: true,
      $props: 'background-color, border-color, color',
      $duration: 0.35s
    );
    svg {
      animation-duration: 0.1s;
      animation-timing-function: ease;
      color: $blue;
      width: rem(6px);
      @include transition($mob: true, $props: 'color', $duration: 0.35s);
      * {
        fill: currentcolor;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Useable */
    /* ---------------------------------------------------------------- */
    &[class*='styles_useable'] {
      /* ---------------------------------------------------------------- */
      /* Focus */
      /* ---------------------------------------------------------------- */
      &:focus {
        border-color: $blue-dark;
        svg {
          color: $blue-dark;
        }
      }
      :global(.platform-desktop) & {
        /* ---------------------------------------------------------------- */
        /* Desktop Hover */
        /* ---------------------------------------------------------------- */
        &:hover {
          background-color: $blue-pastel-light;
          border-color: $blue-dark;
          svg {
            color: $blue-dark;
          }
        }
        /* ---------------------------------------------------------------- */
        /* Desktop Active */
        /* ---------------------------------------------------------------- */
        &:active {
          animation-name: blue-desktop-active;
          svg {
            animation-name: blue-icon-desktop-active;
          }
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Disabled */
    /* ---------------------------------------------------------------- */
    &:disabled {
      border-color: rgba($blue-pastel-dark, 0.4);
      cursor: not-allowed;
      svg {
        color: rgba($blue-pastel-dark, 0.4);
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Input */
  /* ---------------------------------------------------------------- */
  input {
    appearance: none;
    background-color: $white;
    border: rem(1px) solid $blue-pastel-dark;
    border-radius: rem(3px);
    box-sizing: border-box;
    caret-color: $blue-pastel-dark;
    color: $blue-pastel-dark;
    display: inline-block;
    filter: none;
    font-family: $primary;
    font-weight: $regular;
    letter-spacing: normal;
    line-height: normal;
    outline: none;
    resize: none;
    text-align: center;
    vertical-align: middle;
    width: rem(80px);
    @include transition($duration: 0.2s, $timing: 'linear');
    /* ---------------------------------------------------------------- */
    /* Autofill */
    /* ---------------------------------------------------------------- */
    &:-webkit-autofill::first-line,
    &:-webkit-autofill {
      background-color: $white;
      color: $blue-pastel-dark;
      font-family: $primary;
      font-weight: $regular;
      transition: background-color 10000s ease-in-out 0s;
    }
    /* Password Manager - Safari */
    &::-webkit-credentials-auto-fill-button {
      display: none;
      visibility: hidden;
    }
    /* ---------------------------------------------------------------- */
    /* Search */
    /* ---------------------------------------------------------------- */
    &::-webkit-search-cancel-button {
      display: none;
    }
    /* ---------------------------------------------------------------- */
    /* Date */
    /* ---------------------------------------------------------------- */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      display: none;
    }
    /* ---------------------------------------------------------------- */
    /* Placeholder */
    /* ---------------------------------------------------------------- */
    &::placeholder {
      color: rgba($blue-pastel-dark, 0.4);
      opacity: 1;
    }
    /* ---------------------------------------------------------------- */
    /* Focus */
    /* ---------------------------------------------------------------- */
    &:focus {
      border-color: $blue;
      outline: none;
    }
    /* ---------------------------------------------------------------- */
    /* Desktop Hover */
    /* ---------------------------------------------------------------- */
    :global(.platform-desktop) & {
      &:hover {
        border-color: $blue-dark;
        &:focus {
          border-color: $blue;
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Small */
  /* ---------------------------------------------------------------- */
  &.small {
    input {
      font-size: rem(14px);
      height: rem(36px);
      padding: 0 rem(10px);
      &:-webkit-autofill::first-line,
      &:-webkit-autofill {
        font-size: rem(14px);
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Medium */
  /* ---------------------------------------------------------------- */
  &.medium {
    input {
      font-size: rem(16px);
      height: rem(44px);
      padding: 0 rem(14px);
      &:-webkit-autofill::first-line,
      &:-webkit-autofill {
        font-size: rem(16px);
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Invalid */
  /* ---------------------------------------------------------------- */
  &.invalid {
    input {
      border-color: $red;
      :global(.platform-desktop) & {
        &:hover {
          border-color: darken($red, 15%);
        }
        &:focus {
          border-color: $blue;
        }
      }
      &:focus {
        border-color: $blue;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Read Only */
  /* ---------------------------------------------------------------- */
  &.read-only {
    input {
      background-color: rgba($blue-pastel-dark, 0.1);
      cursor: not-allowed;
      :global(.platform-desktop) & {
        &:hover {
          border-color: $blue-pastel-dark;
          &:focus {
            border-color: $blue;
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Disabled */
  /* ---------------------------------------------------------------- */
  &.disabled {
    input {
      border-color: rgba($blue-pastel-dark, 0.4);
      color: rgba($blue-pastel-dark, 0.4);
      cursor: not-allowed;
      &::placeholder {
        color: rgba($blue-pastel-dark, 0.2);
      }
      :global(.platform-desktop) & {
        &:hover {
          border-color: rgba($blue-pastel-dark, 0.4);
        }
      }
    }
  }
}
/* Firefox autofill - won't work with & */
input:autofill {
  background: $white;
}

input {
  -moz-appearance: none;
  -webkit-appearance: none;
}

@keyframes blue-desktop-active {
  50% {
    background-color: #5ca9fa; //rgba($blue, 0.75);
    border-color: #5ca9fa; //rgba($blue, 0.75);
  }
}
@keyframes blue-icon-desktop-active {
  50% {
    color: $white;
  }
}
