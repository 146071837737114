@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* -------------------------------------------------------------- */
/* Policy Holders Summary - History Messages */
/* -------------------------------------------------------------- */
.history-messages-item {
  border-bottom: rem(1px) solid $blue-pastel;
  display: flex;
  position: relative;
  /* -------------------------------------------------------------- */
  /* Button */
  /* -------------------------------------------------------------- */
  button {
    box-sizing: border-box;
    color: $grey-dark;
    cursor: pointer;
    display: flex;
    padding: rem(12px) rem(10px) rem(10px);
    text-align: left;
    width: 100%;
    @include transition($props: 'background-color', $duration: '0.35s');
    &:focus {
      background-color: $green-pastel-light;
      border-color: $blue;
      > svg:nth-of-type(1) {
        opacity: 0;
      }
      > svg:nth-of-type(2) {
        opacity: 1;
      }
    }
    /* -------------------------------------------------------------- */
    /* Desktop Hover */
    /* -------------------------------------------------------------- */
    :global(.platform-desktop) & {
      &:hover {
        background-color: $green-pastel-light;
        > svg:nth-of-type(1) {
          opacity: 0;
        }
        > svg:nth-of-type(2) {
          opacity: 1;
        }
      }
    }
    /* -------------------------------------------------------------- */
    /* Message Type Icon */
    /* -------------------------------------------------------------- */
    > div {
      align-items: center;
      display: flex;
      flex-shrink: 0;
      height: rem(24px);
      justify-content: center;
      position: relative;
      width: rem(24px);
      > svg * {
        fill: currentcolor;
      }
    }
    /* -------------------------------------------------------------- */
    /* Type + Description */
    /* -------------------------------------------------------------- */
    p {
      display: grid;
      grid-gap: rem(4px);
      margin-right: auto;
      padding: 0 rem(20px);
      /* -------------------------------------------------------------- */
      /* Type */
      /* -------------------------------------------------------------- */
      strong {
        font-size: rem(8px);
        font-weight: $extra-bold;
        line-height: rem(10px);
        margin: rem(-1px) 0 rem(-2px);
        text-transform: uppercase;
      }
      /* -------------------------------------------------------------- */
      /* Description */
      /* -------------------------------------------------------------- */
      > span {
        height: rem(15px);
        line-height: rem(14px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        will-change: height;
        @include transition($props: 'height', $duration: '0.35s');
        > span {
          font-size: rem(12px);
          line-height: rem(14px);
        }
      }
    }
    /* -------------------------------------------------------------- */
    /* Date */
    /* -------------------------------------------------------------- */
    > span:not([class^='styles_spinner']) {
      display: flex;
      flex-direction: column;
      margin-left: rem(30px);
      margin-right: rem(30px);
      position: relative;
      text-align: right;
      top: rem(1.5px);
      /* -------------------------------------------------------------- */
      /* Date + Timestamp */
      /* -------------------------------------------------------------- */
      > span {
        font-size: rem(9px);
        line-height: rem(11px);
        margin: rem(-1px) 0 rem(-2px);
        white-space: nowrap;
        &:last-child {
          margin-top: rem(2.5px);
        }
      }
    }
    /* -------------------------------------------------------------- */
    /* Icon */
    /* -------------------------------------------------------------- */
    > svg {
      color: $blue;
      flex-shrink: 0;
      height: rem(12px);
      opacity: 1;
      position: absolute;
      right: rem(10px);
      top: rem(18px);
      width: rem(12px);
      will-change: opacity;
      @include transition($props: 'opacity, color', $duration: '0.35s');
      &:nth-of-type(2) {
        opacity: 0;
      }
      * {
        fill: currentcolor;
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Disabled */
  /* -------------------------------------------------------------- */
  button:disabled {
    cursor: default;
    > svg:nth-of-type(1) {
      color: $grey;
    }
    > svg:nth-of-type(2) {
      color: $grey;
    }
    &:focus {
      > svg:nth-of-type(1) {
        opacity: 1;
      }
      > svg:nth-of-type(2) {
        opacity: 0;
      }
    }
    :global(.platform-desktop) & {
      &:hover {
        > svg:nth-of-type(1) {
          opacity: 1;
        }
        > svg:nth-of-type(2) {
          opacity: 0;
        }
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Fetching */
  /* -------------------------------------------------------------- */
  &.fetching {
    button {
      cursor: wait;
    }
    span[class^='styles_spinner'] {
      height: rem(12px);
      position: absolute;
      right: rem(10px);
      top: rem(18px);
      width: rem(12px);
    }
  }
  /* -------------------------------------------------------------- */
  /* Show Description */
  /* -------------------------------------------------------------- */
  &.show-description {
    p > span {
      white-space: normal;
    }
  }
}
