@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Policy Holders List */
/* ---------------------------------------------------------------- */
.policy-holders-list {
  background: $white;
  border-radius: rem(3px);
  box-shadow: 0 rem(3px) rem(6px) rgba(0, 0, 0, 0.1);
  display: grid;
  margin-bottom: rem(28px);
  overflow: hidden;
  /* ---------------------------------------------------------------- */
  /* Header */
  /* ---------------------------------------------------------------- */
  header {
    align-items: center;
    border-bottom: rem(1px) solid $blue-pastel;
    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    grid-gap: rem(10px);
    height: rem(44px);
    padding: 0 rem(10px);
    text-transform: uppercase;
    /* ---------------------------------------------------------------- */
    /* Label */
    /* ---------------------------------------------------------------- */
    div {
      font-size: rem(8px);
      font-weight: $extra-bold;
      line-height: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Content */
  /* ---------------------------------------------------------------- */
  ul {
    border-bottom: rem(1px) solid $green-pastel-light;
    display: grid;
    overflow-y: auto;
    scrollbar-color: $red-dark transparent;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: rem(8px);
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: rem(8px);
      box-shadow: inset 0 0 rem(6px) rgba(0, 0, 0, 0);
    }
    &::-webkit-scrollbar-thumb {
      background-color: $red-dark;
      border-radius: rem(8px);
    }
  }
  /* ---------------------------------------------------------------- */
  /* Content Item */
  /* ---------------------------------------------------------------- */
  li {
    border-bottom: rem(1px) solid $green-pastel-light;
    position: relative;
    &:last-child {
      border: none;
    }
    /* ---------------------------------------------------------------- */
    /* Button */
    /* ---------------------------------------------------------------- */
    button {
      align-items: center;
      background-color: $white;
      box-sizing: border-box;
      cursor: pointer;
      display: grid;
      grid-auto-columns: minmax(0, 1fr);
      grid-auto-flow: column;
      grid-gap: rem(10px);
      height: rem(44px);
      padding: 0 rem(10px);
      text-align: left;
      width: 100%;
      @include transition($props: 'background-color', $duration: '0.35s');
      /* ---------------------------------------------------------------- */
      /* Focus */
      /* ---------------------------------------------------------------- */
      &:focus {
        background-color: $green-pastel-light;
        outline: rem(2px) solid $blue;
        outline-offset: rem(-2px);
        text-decoration: underline;
      }
      /* ---------------------------------------------------------------- */
      /* Desktop Hover */
      /* ---------------------------------------------------------------- */
      :global(.platform-desktop) & {
        &:hover {
          background-color: $green-pastel-light;
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Value */
    /* ---------------------------------------------------------------- */
    span {
      font-size: rem(14px);
      line-height: rem(17px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &:last-child {
        padding-right: rem(18px);
      }
    }
    /* ---------------------------------------------------------------- */
    /* Chevron */
    /* ---------------------------------------------------------------- */
    svg {
      height: rem(14px);
      position: absolute;
      right: rem(10px);
      top: rem(15px);
      width: rem(8px);
    }
  }
  /* ---------------------------------------------------------------- */
  /* Footer */
  /* ---------------------------------------------------------------- */
  footer {
    align-items: center;
    display: flex;
    font-size: rem(12px);
    height: rem(34px);
    justify-content: flex-end;
    line-height: 1;
    padding: 0 rem(10px);
    position: relative;
    strong {
      font-weight: 700;
    }
    /* ---------------------------------------------------------------- */
    /* Button */
    /* ---------------------------------------------------------------- */
    button {
      align-items: center;
      cursor: pointer;
      display: flex;
      height: rem(34px);
      justify-content: center;
      position: relative;
      width: rem(20px);
      /* ---------------------------------------------------------------- */
      /* Disabled */
      /* ---------------------------------------------------------------- */
      &[disabled] {
        cursor: not-allowed;
        svg {
          color: $grey;
        }
      }
      /* ---------------------------------------------------------------- */
      /* Focus Ring */
      /* ---------------------------------------------------------------- */
      &:focus::after {
        border: rem(2px) solid $blue;
        border-radius: 50%;
        content: '';
        display: block;
        height: rem(20px);
        position: absolute;
        width: rem(20px);
      }
      /* ---------------------------------------------------------------- */
      /* Desktop Hover */
      /* ---------------------------------------------------------------- */
      :global(.platform-desktop) & {
        &:hover:not([disabled]) svg {
          color: $blue-dark;
        }
      }
      /* ---------------------------------------------------------------- */
      /* Button Icon */
      /* ---------------------------------------------------------------- */
      svg {
        color: $blue;
        height: rem(10px);
        width: rem(5px);
        @include transition($props: 'color', $duration: '0.35s');
        * {
          stroke: currentcolor;
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Previous Page Button */
    /* ---------------------------------------------------------------- */
    button:nth-of-type(1) {
      margin-left: rem(12px);
      transform: rotate(180deg);
      &:focus::after {
        left: rem(-3px);
      }
    }
    /* ---------------------------------------------------------------- */
    /* Previous Page Button */
    /* ---------------------------------------------------------------- */
    button:nth-of-type(2) {
      margin-left: rem(6px);
      margin-right: rem(-8px);
      &:focus::after {
        left: rem(-2px);
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Relative Scrollbar */
  /* ---------------------------------------------------------------- */
  &.relative-scrollbar {
    header,
    footer {
      padding-right: rem(18px);
    }
  }
}
