@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Input Container */
/* ---------------------------------------------------------------- */
.input {
  display: block;
  overflow: hidden;
  position: relative;
  /* ---------------------------------------------------------------- */
  /* Input */
  /* ---------------------------------------------------------------- */
  input {
    appearance: none;
    background-color: $white;
    border: rem(1px) solid $blue-pastel-dark;
    border-radius: rem(3px);
    box-sizing: border-box;
    caret-color: $blue-pastel-dark;
    color: $blue-pastel-dark;
    display: inline-block;
    filter: none;
    font-family: $primary;
    font-weight: $regular;
    letter-spacing: normal;
    line-height: normal;
    outline: none;
    resize: none;
    text-align: left;
    vertical-align: middle;
    width: 100%;
    @include transition($duration: 0.2s, $timing: 'linear');
    /* ---------------------------------------------------------------- */
    /* Autofill */
    /* ---------------------------------------------------------------- */
    &:-webkit-autofill::first-line,
    &:-webkit-autofill {
      background-color: $white;
      color: $blue-pastel-dark;
      font-family: $primary;
      font-weight: $regular;
      transition: background-color 10000s ease-in-out 0s;
    }
    /* Password Manager - Safari */
    &::-webkit-credentials-auto-fill-button {
      display: none;
      visibility: hidden;
    }
    /* ---------------------------------------------------------------- */
    /* Search */
    /* ---------------------------------------------------------------- */
    &::-webkit-search-cancel-button {
      display: none;
    }
    /* ---------------------------------------------------------------- */
    /* Date */
    /* ---------------------------------------------------------------- */
    &[type='date'] {
      -webkit-text-fill-color: $blue-pastel-dark;
      text-transform: uppercase;
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        display: none;
      }
      /* Allow clicking input to display date picker Chrome Desktop */
      &::-webkit-calendar-picker-indicator {
        background-color: transparent;
        border: none;
        box-sizing: border-box;
        color: transparent;
        height: 100%;
        left: -100vw;
        outline: none;
        position: absolute;
        right: 0;
        top: 0;
        visibility: hidden;
        width: calc(100% + 100vw);
      }
      /* Text alignment set to center by default in iOS */
      &::-webkit-date-and-time-value {
        text-align: left;
      }
      /* End of value gets cut off on Android */
      :global(.platform-android) & {
        padding-right: 0;
      }
      /* Prevent transition on letter-spacing */
      :global(.browser-firefox) & {
        transition-property: background-color, border-color, color;
      }
      /* Safari selection styles */
      &::-webkit-datetime-edit-ampm-field:focus,
      &::-webkit-datetime-edit-day-field:focus,
      &::-webkit-datetime-edit-hour-field:focus,
      &::-webkit-datetime-edit-millisecond-field:focus,
      &::-webkit-datetime-edit-minute-field:focus,
      &::-webkit-datetime-edit-month-field:focus,
      &::-webkit-datetime-edit-second-field:focus,
      &::-webkit-datetime-edit-week-field:focus,
      &::-webkit-datetime-edit-year-field:focus {
        background-color: highlight;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Placeholder */
    /* ---------------------------------------------------------------- */
    &::placeholder {
      color: rgba($blue-pastel-dark, 0.4);
      opacity: 1;
    }
    /* ---------------------------------------------------------------- */
    /* Focus */
    /* ---------------------------------------------------------------- */
    &:focus {
      border-color: $blue;
      outline: none;
    }
    /* ---------------------------------------------------------------- */
    /* Desktop Hover */
    /* ---------------------------------------------------------------- */
    :global(.platform-desktop) & {
      &:hover {
        border-color: $blue-dark;
        &:focus {
          border-color: $blue;
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Date */
  /* ---------------------------------------------------------------- */
  &.date {
    /* ---------------------------------------------------------------- */
    /* Hide Firefox Clear Date Button */
    /* ---------------------------------------------------------------- */
    :global(.browser-firefox) & {
      &::after {
        background: $white;
        border-radius: 50%;
        content: '';
        display: block;
        height: rem(15px);
        position: absolute;
        right: rem(2px);
        top: 50%;
        transform: translateY(-50%);
        width: rem(14px);
        @include transition(
          $props: 'background-color',
          $duration: 0.2s,
          $timing: 'linear'
        );
      }
    }
    &.no-value {
      /* Extra spacing before and after forward slash for pseudo placeholder */
      input {
        :global(.browser-firefox) & {
          letter-spacing: rem(-0.75px);
        }
      }
      &::after {
        align-items: center;
        background: $white;
        border-radius: rem(3px);
        box-sizing: border-box;
        color: rgba($blue-pastel-dark, 0.4);
        content: 'DD/MM/YYYY';
        display: inline-flex;
        flex-shrink: 0;
        left: rem(1px);
        pointer-events: none;
        position: absolute;
        width: calc(100% - rem(2px));
        :global(html:not(.browser-firefox)) & {
          top: rem(1px);
        }
      }
    }
    &.focused {
      &::after {
        content: none;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Small */
  /* ---------------------------------------------------------------- */
  &.small {
    input {
      font-size: rem(14px);
      height: rem(36px);
      padding: 0 rem(10px);
      &:-webkit-autofill::first-line,
      &:-webkit-autofill {
        font-size: rem(14px);
      }
      &[type='date'] {
        line-height: rem(30px);
        :global(.platform-ios) & {
          line-height: rem(36px);
        }
        :global(.platform-android) & {
          line-height: rem(32px);
        }
        /* Firefox fix: additional padding present around values + clear button takes up additional space on right */
        :global(.browser-firefox) & {
          padding: 0 0 0 rem(8px);
        }
      }
    }
    &.date.no-value {
      &::after {
        font-size: rem(14px);
        height: rem(34px);
        padding: 0 rem(10px);
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Medium */
  /* ---------------------------------------------------------------- */
  &.medium {
    input {
      font-size: rem(16px);
      height: rem(44px);
      padding: 0 rem(14px);
      &:-webkit-autofill::first-line,
      &:-webkit-autofill {
        font-size: rem(16px);
      }
      &[type='date'] {
        line-height: rem(38px);
        :global(.platform-ios) & {
          line-height: rem(44px);
        }
        :global(.platform-android) & {
          line-height: rem(41px);
        }
        /* Firefox fix: additional padding present around values + clear button takes up additional space on right */
        :global(.browser-firefox) & {
          padding: 0 0 0 rem(12px);
        }
      }
    }
    &.date.no-value {
      &::after {
        font-size: rem(16px);
        height: rem(42px);
        padding: 0 rem(14px);
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Invalid */
  /* ---------------------------------------------------------------- */
  &.invalid {
    input {
      border-color: $red;
      :global(.platform-desktop) & {
        &:hover {
          border-color: darken($red, 15%);
        }
        &:focus {
          border-color: $blue;
        }
      }
      &:focus {
        border-color: $blue;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Read Only */
  /* ---------------------------------------------------------------- */
  &.read-only {
    &.date {
      :global(.browser-firefox) & {
        &::after {
          background-color: #efeff0;
        }
      }
    }
    input {
      background-color: rgba($blue-pastel-dark, 0.1);
      cursor: not-allowed;
      :global(.platform-desktop) & {
        &:hover {
          border-color: $blue-pastel-dark;
          &:focus {
            border-color: $blue;
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Disabled */
  /* ---------------------------------------------------------------- */
  &.disabled {
    &.date {
      :global(.browser-firefox) & {
        &::after {
          background-color: $blue-pastel;
        }
      }
    }
    input {
      border-color: rgba($blue-pastel-dark, 0.4);
      color: rgba($blue-pastel-dark, 0.4);
      cursor: not-allowed;
      &::placeholder {
        color: rgba($blue-pastel-dark, 0.2);
      }
      :global(.platform-desktop) & {
        &:hover {
          border-color: rgba($blue-pastel-dark, 0.4);
        }
      }
    }
  }
}
/* Firefox autofill - won't work with & */
input:autofill {
  background: $white;
}

input {
  -moz-appearance: none;
  -webkit-appearance: none;
}
