@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Hyperlink */
/* ---------------------------------------------------------------- */
.hyperlink {
  animation-duration: 0.35s;
  animation-timing-function: ease;
  border: rem(2px) solid transparent;
  border-radius: rem(3px);
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  font-family: $primary;
  font-size: rem(16px);
  font-weight: $bold;
  justify-content: center;
  line-height: rem(18px);
  padding: rem(11px) rem(18px);
  position: relative;
  text-decoration: underline;
  text-decoration-color: transparent;
  text-underline-offset: rem(1px);
  vertical-align: top;
  @include no-user-select;
  @include transition(
    $mob: true,
    $props: 'background-color, border-color, color',
    $duration: 0.35s
  );
  /* ---------------------------------------------------------------- */
  /* Primary */
  /* ---------------------------------------------------------------- */
  &.primary {
    color: $white;
    /* ---------------------------------------------------------------- */
    /* Red */
    /* ---------------------------------------------------------------- */
    &.red {
      background-color: $red;
      &.useable {
        :global(.platform-desktop) & {
          &:hover {
            background-color: $red-dark;
          }
          &:global(.active-animation) {
            animation-name: red-primary-desktop-active;
          }
        }
        :global(.platform-mobile) & {
          &:global(.active-animation) {
            animation-name: red-primary-mobile-active;
          }
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Blue */
    /* ---------------------------------------------------------------- */
    &.blue {
      background-color: $blue;
      &.useable {
        :global(.platform-desktop) & {
          &:hover {
            background-color: $blue-dark;
          }
          &:global(.active-animation) {
            animation-name: blue-primary-desktop-active;
          }
        }
        :global(.platform-mobile) & {
          &:global(.active-animation) {
            animation-name: blue-primary-mobile-active;
          }
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Focus */
    /* ---------------------------------------------------------------- */
    &:focus {
      text-decoration-color: $white;
      &.red {
        background-color: $red-dark;
      }
      &.blue {
        background-color: $blue-dark;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Secondary */
  /* ---------------------------------------------------------------- */
  &.secondary {
    /* ---------------------------------------------------------------- */
    /* Red */
    /* ---------------------------------------------------------------- */
    &.red {
      border-color: $red;
      color: $red;
      &.useable {
        :global(.platform-desktop) & {
          &:hover {
            background-color: rgba($red, 0.2);
          }
          &:global(.active-animation) {
            animation-name: red-secondary-desktop-active;
          }
        }
        :global(.platform-mobile) & {
          &:global(.active-animation) {
            animation-name: red-secondary-mobile-active;
          }
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Blue */
    /* ---------------------------------------------------------------- */
    &.blue {
      border-color: $blue-pastel;
      color: $blue;
      &.useable {
        :global(.platform-desktop) & {
          &:hover {
            background-color: rgba($blue, 0.2);
          }
          &:global(.active-animation) {
            animation-name: blue-secondary-desktop-active;
          }
        }
        :global(.platform-mobile) & {
          &:global(.active-animation) {
            animation-name: blue-secondary-mobile-active;
          }
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Focus */
    /* ---------------------------------------------------------------- */
    &:focus {
      &.red {
        background-color: rgba($red, 0.2);
        text-decoration-color: $red;
      }
      &.blue {
        background-color: rgba($blue, 0.2);
        text-decoration-color: $blue;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Loading */
  /* ---------------------------------------------------------------- */
  &.loading {
    pointer-events: none;
    /* ---------------------------------------------------------------- */
    /* Spinner */
    /* ---------------------------------------------------------------- */
    svg {
      height: rem(24px);
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: rem(24px);
      path {
        fill: currentcolor;
      }
    }
    span {
      opacity: 0;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Disabled */
  /* ---------------------------------------------------------------- */
  &.disabled[class] {
    background-color: $blue-pastel;
    border-color: transparent;
    color: rgba($blue-pastel-dark, 0.4);
    pointer-events: none;
  }
}
/* ---------------------------------------------------------------- */
/* Animations */
/* ---------------------------------------------------------------- */
@keyframes red-primary-mobile-active {
  50% {
    background-color: $red-dark;
  }
}
@keyframes red-primary-desktop-active {
  50% {
    background-color: rgba($red, 0.75);
  }
}
@keyframes blue-primary-mobile-active {
  50% {
    background-color: $blue-dark;
  }
}
@keyframes blue-primary-desktop-active {
  50% {
    background-color: rgba($blue, 0.75);
  }
}
@keyframes red-secondary-mobile-active {
  50% {
    background-color: rgba($red, 0.2);
  }
}
@keyframes red-secondary-desktop-active {
  50% {
    background-color: $red;
    color: $white;
  }
}
@keyframes blue-secondary-mobile-active {
  50% {
    background-color: rgba($blue, 0.2);
  }
}
@keyframes blue-secondary-desktop-active {
  50% {
    background-color: $blue;
    border-color: $blue;
    color: $white;
  }
}
