@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Drawer Template - Additional Details */
/* ---------------------------------------------------------------- */
.drawer-template-additional-details {
  height: 100%;
  margin-top: rem(10px);
  /* ---------------------------------------------------------------- */
  /* Header */
  /* ---------------------------------------------------------------- */
  header {
    align-items: center;
    background-color: $blue-x-dark;
    border-radius: rem(3px) rem(3px) 0 0;
    color: $white;
    display: flex;
    height: rem(30px);
    padding: 0 rem(10px);
    /* ---------------------------------------------------------------- */
    /* Columns */
    /* ---------------------------------------------------------------- */
    > div {
      box-sizing: border-box;
      font-weight: $bold;
      text-transform: uppercase;
    }
    /* ---------------------------------------------------------------- */
    /* Col 1 - Name */
    /* ---------------------------------------------------------------- */
    > div:nth-child(1) {
      padding-right: rem(5px);
      width: rem(110px);
    }
    /* ---------------------------------------------------------------- */
    /* Col 2 - Value */
    /* ---------------------------------------------------------------- */
    > div:nth-child(2) {
      flex-grow: 1;
      padding-left: rem(5px);
    }
  }
  /* ---------------------------------------------------------------- */
  /* Scrollable Container */
  /* ---------------------------------------------------------------- */
  > div {
    box-sizing: border-box;
    height: 100%;
    margin-bottom: rem(1px);
    max-height: rem(621px);
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-color: $red-dark transparent;
    scrollbar-width: thin;
    width: calc(100% - rem(1px));
    :global(.browser-firefox.platform-mac) &,
    :global(.platform-ios:not(.platform-mac)) & {
      padding-right: rem(20px);
      width: 100%;
    }
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: rem(8px);
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: rem(8px);
      box-shadow: inset 0 0 rem(6px) rgba(0, 0, 0, 0);
    }
    &::-webkit-scrollbar-thumb {
      background-color: $red-dark;
      border-radius: rem(8px);
    }
  }
  /* ---------------------------------------------------------------- */
  /* List */
  /* ---------------------------------------------------------------- */
  ul {
    margin-bottom: rem(1px);
  }
  /* ---------------------------------------------------------------- */
  /* Footer */
  /* ---------------------------------------------------------------- */
  footer {
    align-items: center;
    display: flex;
    font-size: rem(12px);
    height: rem(34px);
    justify-content: flex-end;
    line-height: 1;
    margin-bottom: rem(-10px);
    margin-bottom: auto;
    padding: 0 rem(10px);
    position: relative;
    strong {
      font-weight: 700;
    }
    /* ---------------------------------------------------------------- */
    /* Button */
    /* ---------------------------------------------------------------- */
    button {
      align-items: center;
      cursor: pointer;
      display: flex;
      height: rem(34px);
      justify-content: center;
      position: relative;
      width: rem(20px);
      /* ---------------------------------------------------------------- */
      /* Disabled */
      /* ---------------------------------------------------------------- */
      &[disabled] {
        cursor: not-allowed;
        svg {
          color: $grey;
        }
      }
      /* ---------------------------------------------------------------- */
      /* Focus Ring */
      /* ---------------------------------------------------------------- */
      &:focus::after {
        border: rem(2px) solid $blue;
        border-radius: 50%;
        content: '';
        display: block;
        height: rem(20px);
        position: absolute;
        width: rem(20px);
      }
      /* ---------------------------------------------------------------- */
      /* Desktop Hover */
      /* ---------------------------------------------------------------- */
      :global(.platform-desktop) & {
        &:hover:not([disabled]) svg {
          color: $blue-dark;
        }
      }
      /* ---------------------------------------------------------------- */
      /* Button Icon */
      /* ---------------------------------------------------------------- */
      svg {
        color: $blue;
        height: rem(10px);
        width: rem(5px);
        @include transition($props: 'color', $duration: '0.35s');
        * {
          stroke: currentcolor;
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Previous Page Button */
    /* ---------------------------------------------------------------- */
    button:nth-of-type(1) {
      margin-left: rem(12px);
      transform: rotate(180deg);
      &:focus::after {
        left: rem(-3px);
      }
    }
    /* ---------------------------------------------------------------- */
    /* Previous Page Button */
    /* ---------------------------------------------------------------- */
    button:nth-of-type(2) {
      margin-left: rem(6px);
      margin-right: rem(-8px);
      &:focus::after {
        left: rem(-2px);
      }
    }
  }
}
