@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Policy Holders Summary - History Rewards Skeleton */
/* ---------------------------------------------------------------- */
.history-rewards-skeleton {
  display: flex;
  flex-direction: column;
  /* ---------------------------------------------------------------- */
  /* History Rewards Item */
  /* ---------------------------------------------------------------- */
  li {
    border-bottom: rem(1px) solid $blue-pastel;
    color: $grey-dark;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    &::after {
      @include skeleton-shimmer;
      @include skeleton-shimmer-color($color: $white);
    }
    /* -------------------------------------------------------------- */
    /* Container */
    /* -------------------------------------------------------------- */
    > div:first-child {
      align-items: center;
      display: flex;
      padding: rem(12px) rem(10px);
    }
    /* -------------------------------------------------------------- */
    /* Icon */
    /* -------------------------------------------------------------- */
    > div > div {
      align-items: center;
      background-color: $grey-light;
      border-radius: 50%;
      display: flex;
      height: rem(24px);
      justify-content: center;
      width: rem(24px);
      > svg * {
        fill: currentcolor;
      }
    }
    /* -------------------------------------------------------------- */
    /* Date of Reward + Description */
    /* -------------------------------------------------------------- */
    > div p {
      display: grid;
      grid-gap: rem(4px);
      margin-right: auto;
      padding: 0 rem(20px);
      /* -------------------------------------------------------------- */
      /* Date of Reward */
      /* -------------------------------------------------------------- */
      strong {
        font-size: rem(8px);
        font-weight: $extra-bold;
        line-height: rem(10px);
        margin: rem(-1px) 0 rem(-2px);
        text-transform: uppercase;
        &::after {
          background-color: $grey-light;
          color: transparent;
          content: '24 Feb 23';
          font-size: inherit;
          line-height: inherit;
        }
      }
      /* -------------------------------------------------------------- */
      /* Description */
      /* -------------------------------------------------------------- */
      span {
        font-size: rem(12px);
        line-height: rem(14px);
        margin: rem(-2px) 0;
        &::after {
          background-color: $grey-light;
          color: transparent;
          content: 'Points Awarded';
          font-size: inherit;
          line-height: inherit;
        }
      }
    }
    /* -------------------------------------------------------------- */
    /* Points */
    /* -------------------------------------------------------------- */
    > div > span:last-child {
      display: grid;
      grid-gap: rem(4px);
      text-align: right;
      /* -------------------------------------------------------------- */
      /* Total */
      /* -------------------------------------------------------------- */
      > span:first-child {
        font-size: rem(8px);
        font-weight: $extra-bold;
        line-height: rem(10px);
        margin: rem(-1px) 0 rem(-2px);
        text-transform: uppercase;
        &::after {
          background-color: $grey-light;
          color: transparent;
          content: '395 pts';
          font-size: inherit;
          line-height: inherit;
        }
      }
      /* -------------------------------------------------------------- */
      /* Difference */
      /* -------------------------------------------------------------- */
      > span:last-child {
        font-size: rem(12px);
        line-height: rem(14px);
        margin: rem(-2px) 0;
        &::after {
          background-color: $grey-light;
          color: transparent;
          content: '+14 pts';
          font-size: inherit;
          line-height: inherit;
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Footer */
  /* ---------------------------------------------------------------- */
  footer {
    align-items: center;
    display: flex;
    font-size: rem(12px);
    height: rem(34px);
    justify-content: flex-end;
    line-height: 1;
    margin-bottom: rem(-10px);
    overflow: hidden;
    padding: 0 rem(10px);
    position: relative;
    &::after {
      @include skeleton-shimmer;
      @include skeleton-shimmer-color($color: $white);
    }
    strong {
      font-weight: 700;
    }
    /* ---------------------------------------------------------------- */
    /* Pagination */
    /* ---------------------------------------------------------------- */
    > div:first-child {
      &::after {
        background-color: $grey-light;
        color: transparent;
        content: ' of 83';
      }
      strong {
        &::after {
          background-color: $grey-light;
          color: transparent;
          content: '1 - 10';
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Previous Page Button */
    /* ---------------------------------------------------------------- */
    div:nth-of-type(2) {
      margin-left: rem(12px);
    }
    /* ---------------------------------------------------------------- */
    /* Previous Page Button */
    /* ---------------------------------------------------------------- */
    div:nth-of-type(3) {
      margin-left: rem(6px);
      margin-right: rem(-8px);
    }
    /* ---------------------------------------------------------------- */
    /* Button */
    /* ---------------------------------------------------------------- */
    > div:not(:first-child) {
      align-items: center;
      cursor: wait;
      display: flex;
      height: rem(34px);
      justify-content: center;
      position: relative;
      width: rem(20px);
      /* ---------------------------------------------------------------- */
      /* Button Icon */
      /* ---------------------------------------------------------------- */
      > span {
        background-color: $grey-light;
        height: rem(14.5px);
        width: rem(5px);
      }
    }
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
