@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Breadcrumb */
/* ---------------------------------------------------------------- */
.breadcrumb {
  margin-bottom: rem(11px);
  /* ---------------------------------------------------------------- */
  /* List */
  /* ---------------------------------------------------------------- */
  ol {
    display: flex;
  }
  /* ---------------------------------------------------------------- */
  /* Item */
  /* ---------------------------------------------------------------- */
  li {
    display: inline-flex;
    @include no-user-select;
    &:not(:last-child)::after {
      color: $blue-x-dark;
      content: '>';
      display: block;
      flex-shrink: 0;
      font-size: rem(12px);
      line-height: rem(13px);
      margin: 0 rem(4px);
      pointer-events: none;
      position: relative;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Text */
  /* ---------------------------------------------------------------- */
  span {
    display: block;
    font-size: rem(12px);
    line-height: rem(13px);
  }
  /* ---------------------------------------------------------------- */
  /* Link */
  /* ---------------------------------------------------------------- */
  a {
    align-items: center;
    animation-duration: 0.35s;
    animation-timing-function: ease-in-out;
    color: $blue;
    text-decoration: underline;
    text-underline-offset: rem(1px);
    @include transition(
      $mob: true,
      $props: 'color, text-decoration-color',
      $duration: 0.35s
    );
    /* ---------------------------------------------------------------- */
    /* Focus */
    /* ---------------------------------------------------------------- */
    &:focus {
      color: $red;
      text-decoration-color: $red-dark;
    }
    /* ---------------------------------------------------------------- */
    /* Desktop Hover / Active */
    /* ---------------------------------------------------------------- */
    :global(.platform-desktop) & {
      &:hover {
        color: $blue-x-dark;
      }
      &:global(.active-animation) {
        animation-name: desktop-active;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Mobile Active */
    /* ---------------------------------------------------------------- */
    :global(.platform-mobile) & {
      &:global(.active-animation) {
        animation-name: mobile-active;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Current Page */
  /* ---------------------------------------------------------------- */
  li > span {
    color: $blue-x-dark;
  }
}

@keyframes desktop-active {
  50% {
    color: $blue-x-dark;
    text-decoration-color: transparent;
  }
}
@keyframes mobile-active {
  50% {
    color: $blue-x-dark;
  }
}
