@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Policy Holders Summary - Details */
/* ---------------------------------------------------------------- */
.policy-details {
  /* ---------------------------------------------------------------- */
  /* Header */
  /* ---------------------------------------------------------------- */
  header {
    align-items: center;
    display: flex;
    position: relative;
    /* ---------------------------------------------------------------- */
    /* Icon */
    /* ---------------------------------------------------------------- */
    svg {
      color: $grey-x-dark;
      height: rem(15px);
      margin-right: rem(11px);
      order: 1;
      width: rem(14px);
      * {
        fill: currentcolor;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Title */
    /* ---------------------------------------------------------------- */
    h2 {
      order: 2;
    }
    /* ---------------------------------------------------------------- */
    /* Border */
    /* ---------------------------------------------------------------- */
    &::after {
      background-color: $grey-light;
      bottom: 0;
      content: '';
      height: rem(1px);
      left: rem(20px);
      position: absolute;
      right: rem(20px);
      width: calc(100% - rem(40px));
    }
  }
  /* ---------------------------------------------------------------- */
  /* Content */
  /* ---------------------------------------------------------------- */
  > div {
    display: grid;
    grid-gap: rem(20px);
  }
  /* ---------------------------------------------------------------- */
  /* Detail List */
  /* ---------------------------------------------------------------- */
  ul {
    display: grid;
    grid-gap: rem(20px);
    grid-template-columns: repeat(2, 1fr);
    margin-top: rem(20px);
  }
  /* ---------------------------------------------------------------- */
  /* Detail Item */
  /* ---------------------------------------------------------------- */
  li {
    color: $grey-dark;
    display: grid;
    grid-gap: rem(6px);
  }
  /* ---------------------------------------------------------------- */
  /* Label */
  /* ---------------------------------------------------------------- */
  strong {
    font-size: rem(8px);
    font-weight: $extra-bold;
    line-height: rem(10px);
    margin: rem(-1px) 0 rem(-2px);
    text-transform: uppercase;
    @include no-user-select;
  }
  /* ---------------------------------------------------------------- */
  /* Value */
  /* ---------------------------------------------------------------- */
  li span {
    font-size: rem(14px);
    line-height: rem(17px);
    margin: rem(-3px) 0;
  }
  /* ---------------------------------------------------------------- */
  /* Button */
  /* ---------------------------------------------------------------- */
  button {
    justify-self: flex-start;
  }
}
