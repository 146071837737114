@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Drawer Template - Additional Details Skeleton */
/* ---------------------------------------------------------------- */
.drawer-template-additional-details-skeleton {
  height: 100%;
  margin-top: rem(10px);
  /* ---------------------------------------------------------------- */
  /* Header */
  /* ---------------------------------------------------------------- */
  header {
    align-items: center;
    background-color: $blue-x-dark;
    border-radius: rem(3px) rem(3px) 0 0;
    color: $white;
    display: flex;
    height: rem(30px);
    padding: 0 rem(10px);
    /* ---------------------------------------------------------------- */
    /* Columns */
    /* ---------------------------------------------------------------- */
    > div {
      box-sizing: border-box;
      font-weight: $bold;
      text-transform: uppercase;
    }
    /* ---------------------------------------------------------------- */
    /* Col 1 - Name */
    /* ---------------------------------------------------------------- */
    > div:nth-child(1) {
      padding-right: rem(5px);
      width: rem(110px);
    }
    /* ---------------------------------------------------------------- */
    /* Col 2 - Value */
    /* ---------------------------------------------------------------- */
    > div:nth-child(2) {
      flex-grow: 1;
      padding-left: rem(5px);
    }
  }
  /* ---------------------------------------------------------------- */
  /* Scrollable Container */
  /* ---------------------------------------------------------------- */
  > div {
    box-sizing: border-box;
    height: 100%;
    margin-bottom: rem(1px);
    max-height: rem(621px);
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-color: $red-dark transparent;
    scrollbar-width: thin;
    width: calc(100% - rem(1px));
    :global(.browser-firefox.platform-mac) &,
    :global(.platform-ios:not(.platform-mac)) & {
      padding-right: rem(20px);
      width: 100%;
    }
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: rem(8px);
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: rem(8px);
      box-shadow: inset 0 0 rem(6px) rgba(0, 0, 0, 0);
    }
    &::-webkit-scrollbar-thumb {
      background-color: $red-dark;
      border-radius: rem(8px);
    }
  }
  /* ---------------------------------------------------------------- */
  /* List */
  /* ---------------------------------------------------------------- */
  ul {
    margin-bottom: rem(1px);
  }
  /* ---------------------------------------------------------------- */
  /* Drawer Template - Additional Detail Field */
  /* ---------------------------------------------------------------- */
  li {
    align-items: center;
    border-bottom: rem(1px) solid $blue-pastel;
    display: flex;
    height: rem(30px);
    overflow: hidden;
    padding: 0 rem(10px);
    position: relative;
    &::after {
      @include skeleton-shimmer;
      @include skeleton-shimmer-color($color: $white);
    }
    &:focus {
      outline: none;
    }
    /* ---------------------------------------------------------------- */
    /* Columns */
    /* ---------------------------------------------------------------- */
    > div {
      box-sizing: border-box;
      text-overflow: ellipsis;
      white-space: nowrap;
      &::after {
        background-color: $grey-light;
        color: transparent;
        font-size: inherit;
        line-height: inherit;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Col 1 - Name */
    /* ---------------------------------------------------------------- */
    > div:nth-child(1) {
      flex-shrink: 0;
      padding-right: rem(5px);
      width: rem(110px);
      &::after {
        content: 'SegmentThisWeek';
      }
    }
    /* ---------------------------------------------------------------- */
    /* Col 2 - Value */
    /* ---------------------------------------------------------------- */
    > div:nth-child(2) {
      flex-grow: 1;
      padding-left: rem(5px);
      &::after {
        content: 'readmore';
      }
    }
    /* ---------------------------------------------------------------- */
    /* Edit Button */
    /* ---------------------------------------------------------------- */
    > span {
      background-color: $grey-light;
      border-radius: 50%;
      flex-shrink: 0;
      height: rem(14px);
      margin-left: rem(10px);
      width: rem(14px);
    }
  }
  /* ---------------------------------------------------------------- */
  /* Footer */
  /* ---------------------------------------------------------------- */
  footer {
    align-items: center;
    display: flex;
    font-size: rem(12px);
    height: rem(34px);
    justify-content: flex-end;
    line-height: 1;
    margin-bottom: rem(-10px);
    overflow: hidden;
    padding: 0 rem(10px);
    position: relative;
    &::after {
      @include skeleton-shimmer;
      @include skeleton-shimmer-color($color: $white);
    }
    strong {
      font-weight: 700;
    }
    /* ---------------------------------------------------------------- */
    /* Pagination */
    /* ---------------------------------------------------------------- */
    > div:first-child {
      &::after {
        background-color: $grey-light;
        color: transparent;
        content: ' of 83';
      }
      strong {
        &::after {
          background-color: $grey-light;
          color: transparent;
          content: '1 - 10';
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Previous Page Button */
    /* ---------------------------------------------------------------- */
    div:nth-of-type(2) {
      margin-left: rem(12px);
    }
    /* ---------------------------------------------------------------- */
    /* Previous Page Button */
    /* ---------------------------------------------------------------- */
    div:nth-of-type(3) {
      margin-left: rem(6px);
      margin-right: rem(-8px);
    }
    /* ---------------------------------------------------------------- */
    /* Button */
    /* ---------------------------------------------------------------- */
    > div:not(:first-child) {
      align-items: center;
      cursor: pointer;
      display: flex;
      height: rem(34px);
      justify-content: center;
      position: relative;
      width: rem(20px);
      /* ---------------------------------------------------------------- */
      /* Button Icon */
      /* ---------------------------------------------------------------- */
      > span {
        background-color: $grey-light;
        height: rem(14.5px);
        width: rem(5px);
      }
    }
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
