@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Trips Summary Item */
/* ---------------------------------------------------------------- */
.trips-summary-item {
  @include no-user-select;
  &:not(:last-child) {
    margin-bottom: rem(10px);
  }
  /* ---------------------------------------------------------------- */
  /* Button */
  /* ---------------------------------------------------------------- */
  button {
    background-color: transparent;
    border: rem(1px) solid $grey-light;
    border-radius: rem(3px);
    cursor: pointer;
    padding: rem(15px) rem(56px) rem(15px) rem(10px);
    position: relative;
    text-decoration-color: transparent;
    touch-action: manipulation;
    width: 100%;
    @include transition(
      $props: 'background-color, border-color, text-decoration-color',
      $duration: 0.35s
    );
    /* ---------------------------------------------------------------- */
    /* Focus */
    /* ---------------------------------------------------------------- */
    &:focus {
      background-color: rgba($blue-pastel-light, 0.5);
      border-color: $red;
      text-decoration: underline;
      text-decoration-color: $grey-dark;
      text-underline-offset: rem(1px);
    }
    /* ---------------------------------------------------------------- */
    /* Desktop Hover */
    /* ---------------------------------------------------------------- */
    :global(.platform-desktop) & {
      &:hover {
        background-color: rgba($blue-pastel-light, 0.5);
        border-color: $blue;
      }
    }
    > span {
      color: $grey-dark;
      display: flex;
      @include transition($props: 'opacity', $duration: 0.35s);
      /* ---------------------------------------------------------------- */
      /* Date Time Container */
      /* ---------------------------------------------------------------- */
      &:first-child {
        font-size: rem(12px);
        line-height: rem(15px);
        margin: rem(-3px) 0;
        padding-bottom: rem(10px);
        white-space: pre;
      }
      /* ---------------------------------------------------------------- */
      /* Duration / Distance / Invalid Container */
      /* ---------------------------------------------------------------- */
      &:nth-child(2) {
        font-size: rem(10px);
        line-height: rem(12px);
        /* ---------------------------------------------------------------- */
        /* Duration / Distance */
        /* ---------------------------------------------------------------- */
        > span {
          align-items: center;
          display: flex;
          margin: 0 rem(20px) 0 0;
          /* ---------------------------------------------------------------- */
          /* Duration */
          /* ---------------------------------------------------------------- */
          &:nth-child(1) {
            flex-shrink: 0;
            min-width: rem(69px);
          }
          /* ---------------------------------------------------------------- */
          /* Invalid */
          /* ---------------------------------------------------------------- */
          &:nth-child(3) {
            color: white;
            font-size: rem(8px);
            line-height: rem(10px);
            margin: 0 rem(17px) 0 auto;
            position: relative;
            > span {
              position: relative;
              z-index: 1;
            }
            &::before {
              background-color: $red;
              border-radius: rem(3px);
              bottom: rem(-4px);
              content: '';
              display: block;
              left: rem(-7px);
              margin: rem(2px) 0;
              position: absolute;
              right: rem(-7px);
              top: rem(-4px);
              z-index: 0;
            }
          }
        }
      }
      /* ---------------------------------------------------------------- */
      /* Score Container */
      /* ---------------------------------------------------------------- */
      &:nth-child(3) {
        align-items: center;
        background-color: $green-pastel-light;
        border-radius: 50%;
        font-size: rem(16px);
        font-weight: $bold;
        height: rem(36px);
        justify-content: center;
        line-height: rem(19px);
        margin-top: rem(-18px);
        position: absolute;
        right: rem(10px);
        top: 50%;
        width: rem(36px);
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Icons (Duration / Distance) */
  /* ---------------------------------------------------------------- */
  svg {
    height: rem(12px);
    margin: 0 rem(8px) 0 0;
    width: rem(12px);
  }
  /* ---------------------------------------------------------------- */
  /* Date Time */
  /* ---------------------------------------------------------------- */
  time {
    font-size: rem(12px);
    line-height: rem(15px);
  }
  /* ---------------------------------------------------------------- */
  /* Selected */
  /* ---------------------------------------------------------------- */
  &.selected {
    button {
      background-color: $blue-pastel-light;
      pointer-events: none;
      :global(.platform-desktop) & {
        &:hover {
          background-color: $blue-pastel-light;
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Invalid */
  /* ---------------------------------------------------------------- */
  &.invalid {
    button {
      > span:first-child,
      > span:nth-child(2) > span:not(:nth-child(3)) {
        opacity: 0.5;
      }
    }
  }
}
