@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Trips List Pagination Error */
/* ---------------------------------------------------------------- */
.trips-list-pagination-error {
  align-items: center;
  color: $red;
  display: flex;
  flex-direction: column;
  font-size: rem(12px);
  line-height: rem(13px);
  margin: rem(-2px) 0;
  padding: rem(15px) 0 rem(24px);
  text-align: center;
  > button {
    margin-top: rem(12px);
  }
}
