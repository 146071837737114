@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* -------------------------------------------------------------- */
/* Road Speed Vs Travel Speed - Line Chart */
/* -------------------------------------------------------------- */
.charts {
  position: relative;
  @include no-user-select;
  /* -------------------------------------------------------------- */
  /* Header */
  /* -------------------------------------------------------------- */
  header {
    margin-bottom: rem(8px);
  }
  /* -------------------------------------------------------------- */
  /* Legend */
  /* -------------------------------------------------------------- */
  .legend {
    display: flex;
    flex-wrap: wrap;
    margin: rem(-4px) 0;
    position: absolute;
    right: rem(20px);
    top: rem(20px);
    li {
      align-items: center;
      display: flex;
      font-size: rem(12px);
      line-height: rem(13px);
      margin: rem(3px) rem(19px) rem(3px) 0;
      padding-left: rem(1px);
      position: relative;
      &:last-child {
        margin-right: 0;
      }
      /* -------------------------------------------------------------- */
      /* Road Speed */
      /* -------------------------------------------------------------- */
      &:nth-child(1) > span:nth-child(1) {
        background-color: #e62a29;
      }
      /* -------------------------------------------------------------- */
      /* Travel Speed */
      /* -------------------------------------------------------------- */
      &:nth-child(2) > span:nth-child(1) {
        background-color: #0e8cf8;
      }
      /* -------------------------------------------------------------- */
      /* Icon */
      /* -------------------------------------------------------------- */
      > span:nth-child(1) {
        border-radius: 50%;
        display: block;
        height: rem(6px);
        margin-right: rem(5px);
        width: rem(6px);
      }
      /* -------------------------------------------------------------- */
      /* Text */
      /* -------------------------------------------------------------- */
      > span:nth-child(2) {
        color: $blue-x-dark;
        position: relative;
        text-transform: capitalize;
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Chart Container */
  /* -------------------------------------------------------------- */
  .chart-container {
    height: rem(132px);
    margin: rem(-9px) rem(-4px) rem(-5px) rem(-1px);
  }
  figure {
    margin: rem(-5px) 0 0;
  }
  /* -------------------------------------------------------------- */
  /* Chart Figcaption */
  /* -------------------------------------------------------------- */
  figcaption {
    top: 0;
    // Required - otherwise table creates additional height on iOS and Android
    // causing body scrolling when phone is oriented horizontally
  }
  /* -------------------------------------------------------------- */
  /* Cartesian Grid */
  /* -------------------------------------------------------------- */
  :global(.recharts-cartesian-grid) {
    line {
      stroke: $grey-light;
      stroke-width: rem(1px);
    }
  }
  /* -------------------------------------------------------------- */
  /* Axis Ticks */
  /* -------------------------------------------------------------- */
  :global(.recharts-cartesian-axis-tick) {
    font-size: rem(8px);
    line-height: rem(10px);
    > text {
      fill: $grey-dark;
    }
  }
}
