@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Policy Holders Page Title */
/* ---------------------------------------------------------------- */
.policy-holders-page-title {
  /* ---------------------------------------------------------------- */
  /* Container */
  /* ---------------------------------------------------------------- */
  > div {
    box-sizing: border-box;
    padding-right: rem(186px);
  }
  /* ---------------------------------------------------------------- */
  /* Loading */
  /* ---------------------------------------------------------------- */
  /* ---------------------------------------------------------------- */
  /* Loading */
  /* ---------------------------------------------------------------- */
  &.details-loading {
    h1 span {
      overflow: hidden;
      position: relative;
      &::before {
        @include skeleton-shimmer;
        @include skeleton-shimmer-color($color: $white);
      }
      &::after {
        background-color: $grey-light;
        color: transparent;
        font-size: inherit;
        line-height: inherit;
      }
      &:nth-child(1)::after {
        content: 'Johan';
      }
      &:nth-child(2)::after {
        content: 'Bloggers';
      }
    }
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
