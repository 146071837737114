@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Select Container */
/* ---------------------------------------------------------------- */
.select {
  position: relative;
  /* ---------------------------------------------------------------- */
  /* Select */
  /* ---------------------------------------------------------------- */
  select {
    appearance: none;
    background-color: $white;
    border: rem(1px) solid $blue-pastel-dark;
    border-radius: rem(3px);
    box-sizing: border-box;
    caret-color: $blue-pastel-dark;
    color: $blue-pastel-dark;
    display: inline-block;
    filter: none;
    font-family: $primary;
    font-weight: $regular;
    letter-spacing: normal;
    line-height: normal;
    outline: none;
    resize: none;
    vertical-align: middle;
    width: 100%;
    @include no-user-select;
    @include transition($duration: 0.2s, $timing: 'linear');
    /* ---------------------------------------------------------------- */
    /* Focus */
    /* ---------------------------------------------------------------- */
    &:focus {
      border-color: $blue;
    }
    /* ---------------------------------------------------------------- */
    /* Desktop Hover */
    /* ---------------------------------------------------------------- */
    :global(.platform-desktop) & {
      &:hover {
        border-color: $blue-dark;
        &:focus {
          border-color: $blue;
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Option */
    /* ---------------------------------------------------------------- */
    option {
      color: $blue-pastel-dark;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Placeholder */
  /* ---------------------------------------------------------------- */
  &.placeholder {
    select {
      color: rgba($blue-pastel-dark, 0.4);
    }
  }
  /* ---------------------------------------------------------------- */
  /* Chevron */
  /* ---------------------------------------------------------------- */
  svg {
    color: $grey;
    content: '';
    display: block;
    flex-shrink: 0;
    height: rem(11px);
    margin-top: rem(-5.5px);
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: rotate(-90deg);
    width: rem(6px);
    * {
      fill: currentcolor;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Small */
  /* ---------------------------------------------------------------- */
  &.small {
    svg {
      right: rem(14px);
    }
    select {
      font-size: rem(14px);
      height: rem(36px);
      padding: 0 rem(32px) 0 rem(10px);
    }
  }
  /* ---------------------------------------------------------------- */
  /* Medium */
  /* ---------------------------------------------------------------- */
  &.medium {
    svg {
      right: rem(18px);
    }
    select {
      font-size: rem(16px);
      height: rem(44px);
      padding: 0 rem(40px) 0 rem(14px);
    }
  }
  /* ---------------------------------------------------------------- */
  /* Invalid */
  /* ---------------------------------------------------------------- */
  &.invalid {
    select {
      border-color: $red;
      :global(.platform-desktop) & {
        &:hover {
          border-color: darken($red, 15%);
        }
        &:focus {
          border-color: $blue;
        }
      }
      &:focus {
        border-color: $blue;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Read Only */
  /* ---------------------------------------------------------------- */
  &.read-only {
    cursor: not-allowed;
    select {
      background-color: rgba($blue-pastel-dark, 0.1);
      opacity: 1;
      pointer-events: none;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Disabled */
  /* ---------------------------------------------------------------- */
  &.disabled {
    select {
      border-color: rgba($blue-pastel-dark, 0.4);
      color: rgba($blue-pastel-dark, 0.4);
      cursor: not-allowed;
      opacity: 1;
      :global(.platform-desktop) & {
        &:hover {
          border-color: rgba($blue-pastel-dark, 0.4);
        }
      }
    }
    &.placeholder select {
      color: rgba($blue-pastel-dark, 0.2);
    }
  }
}
/* Override Safari browser styles */
select {
  -webkit-appearance: none;
}
