@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Page Title */
/* ---------------------------------------------------------------- */
.page-title {
  border-bottom: rem(1px) solid $grey-light;
  margin: 0 rem(28px);
  padding-top: rem(24px);
  /* ---------------------------------------------------------------- */
  /* Page Title / ID Container */
  /* ---------------------------------------------------------------- */
  > div {
    align-items: center;
    display: flex;
    padding: 0 0 rem(20px);
    position: relative;
    width: 100%;
  }
  /* ---------------------------------------------------------------- */
  /* H1 */
  /* ---------------------------------------------------------------- */
  h1 {
    color: $grey-dark;
    font-family: $secondary;
    font-size: rem(24px);
    font-weight: $black;
    line-height: rem(30px);
    margin: rem(-4px) 0 rem(-9px);
    padding-right: rem(24px);
    @include no-user-select;
  }
}
