@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Modal Template - Date Range */
/* ---------------------------------------------------------------- */
.modal-template-date-range {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  &:first-child {
    padding-top: rem(32px);
  }
  /* ---------------------------------------------------------------- */
  /* Inner Wrapper */
  /* ---------------------------------------------------------------- */
  > div {
    display: flex;
    /* ---------------------------------------------------------------- */
    /* Field Container */
    /* ---------------------------------------------------------------- */
    &:first-child {
      padding: 0 rem(20px) rem(23px);
      /* ---------------------------------------------------------------- */
      /* Last Question */
      /* ---------------------------------------------------------------- */
      > div:last-child {
        margin: 0;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Question Containers */
    /* ---------------------------------------------------------------- */
    > div {
      margin: 0 rem(12px) 0 0;
      width: calc(50% - rem(6px));
    }
  }
}
