@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Filter By Date Form */
/* ---------------------------------------------------------------- */
.filter-by-date-form {
  /* ---------------------------------------------------------------- */
  /* Inner Wrapper */
  /* ---------------------------------------------------------------- */
  > div {
    display: flex;
    /* ---------------------------------------------------------------- */
    /* Question Containers */
    /* ---------------------------------------------------------------- */
    > div {
      margin: 0 0.8rem 0 0;
      width: calc(50% - 3.4rem);
    }
    /* ---------------------------------------------------------------- */
    /* Search Button */
    /* ---------------------------------------------------------------- */
    > button {
      align-self: flex-start;
      flex-shrink: 0;
      font-size: rem(12px);
      margin-top: rem(16px);
      padding-bottom: rem(7px);
      padding-top: rem(7px);
      width: rem(52px);
    }
  }
  /* ---------------------------------------------------------------- */
  /* Filtered Range */
  /* ---------------------------------------------------------------- */
  > p {
    color: $blue-x-dark;
    font-size: rem(10px);
    line-height: rem(13px);
    margin: rem(-3px) 0 rem(-2px);
    padding-top: rem(20px);
  }
}
