@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Modals */
/* ---------------------------------------------------------------- */
.modals {
  height: 0;
  position: relative;
  width: 0;
  z-index: 9998;
}
/* ---------------------------------------------------------------- */
/* Modal Template */
/* ---------------------------------------------------------------- */
.modal-template {
  background-color: $white;
  border-radius: rem(5px);
  box-shadow: 0 rem(12px) rem(24px) rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  max-height: calc(100% - rem(48px));
  max-width: calc(100% - rem(48px));
  overflow: hidden;
  width: rem(480px);
  /* ---------------------------------------------------------------- */
  /* Header */
  /* ---------------------------------------------------------------- */
  > header {
    padding: rem(32px) rem(20px) rem(24px);
    + div {
      padding-top: 0;
    }
    /* ---------------------------------------------------------------- */
    /* Title */
    /* ---------------------------------------------------------------- */
    h2 {
      color: $grey-dark;
      font-size: rem(18px);
      font-weight: $bold;
      line-height: rem(22px);
      margin: rem(-4px) 0;
      &:not(:last-child) {
        padding-bottom: rem(16px);
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Content */
  /* ---------------------------------------------------------------- */
  > div {
    padding: rem(32px) rem(20px);
    &:empty {
      padding: 0;
    }
    /* ---------------------------------------------------------------- */
    /* Text */
    /* ---------------------------------------------------------------- */
    p {
      font-size: rem(16px);
      line-height: rem(19px);
      margin: rem(-4px) 0 rem(-3px);
      &:not(:last-child) {
        padding-bottom: rem(16px);
      }
    }
    /* ---------------------------------------------------------------- */
    /* Link */
    /* ---------------------------------------------------------------- */
    a {
      color: $red;
      @include transition($props: 'color', $duration: 0.35s);
      &:focus {
        color: $red-dark;
        text-decoration: underline;
      }
      :global(.platform-desktop) & {
        &:hover {
          color: $red-dark;
        }
        &:active {
          color: darken($red-dark, 10%);
        }
      }
      :global(.platform-mobile) & {
        &:active {
          color: $red-dark;
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Scrollable Content */
  /* ---------------------------------------------------------------- */
  > div,
  > form > div:first-child {
    box-sizing: border-box;
    margin-bottom: rem(1px);
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-color: $red-dark transparent;
    scrollbar-width: thin;
    width: calc(100% - rem(1px));
    :global(.browser-firefox.platform-mac) &,
    :global(.platform-ios:not(.platform-mac)) & {
      padding-right: rem(20px);
      width: 100%;
    }
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: rem(8px);
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: rem(8px);
      box-shadow: inset 0 0 rem(6px) rgba(0, 0, 0, 0);
    }
    &::-webkit-scrollbar-thumb {
      background-color: $red-dark;
      border-radius: rem(8px);
    }
  }
  /* ---------------------------------------------------------------- */
  /* Button Container */
  /* ---------------------------------------------------------------- */
  footer {
    background-color: $green-pastel-light;
    display: flex;
    padding: rem(20px);
    /* ---------------------------------------------------------------- */
    /* Button */
    /* ---------------------------------------------------------------- */
    a,
    button {
      margin: 0 0 0 rem(12px);
      min-width: rem(120px);
      &:first-child {
        margin-left: auto;
      }
    }
  }
}
