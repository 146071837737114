@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* -------------------------------------------------------------- */
/* Score Over Time - Line Chart */
/* -------------------------------------------------------------- */
.charts {
  position: relative;
  @include no-user-select;
  /* -------------------------------------------------------------- */
  /* Header */
  /* -------------------------------------------------------------- */
  header {
    align-items: center;
    display: flex;
    flex-direction: row;
    position: relative;
    /* -------------------------------------------------------------- */
    /* Border */
    /* -------------------------------------------------------------- */
    &::after {
      background-color: $grey-light;
      bottom: rem(9px);
      content: '';
      height: rem(1px);
      left: rem(30px);
      position: absolute;
      width: calc(100% - rem(60px));
    }
    /* -------------------------------------------------------------- */
    /* Button Container */
    /* -------------------------------------------------------------- */
    > div {
      display: flex;
      margin-left: auto;
    }
    /* -------------------------------------------------------------- */
    /* Button */
    /* -------------------------------------------------------------- */
    button {
      align-items: center;
      border: rem(1px) solid $grey-light;
      border-radius: rem(18px);
      color: $blue-x-dark;
      cursor: pointer;
      display: inline-flex;
      font-size: rem(12px);
      height: rem(24px);
      line-height: rem(13px);
      padding: 0 rem(10px);
      position: relative;
      text-decoration: underline;
      text-decoration-color: transparent;
      text-underline-offset: rem(1px);
      @include transition(
        $props: 'background-color, border-color, color',
        $duration: 0.35s
      );
      &:not(:last-child) {
        margin-right: rem(10px);
      }
      /* -------------------------------------------------------------- */
      /* Focus */
      /* -------------------------------------------------------------- */
      &:focus {
        background-color: $blue-pastel-light;
        text-decoration-color: $blue-x-dark;
      }
      /* -------------------------------------------------------------- */
      /* Hover */
      /* -------------------------------------------------------------- */
      :global(.platform-desktop) & {
        &:not(.selected):not(:disabled):hover {
          background-color: $blue-pastel-light;
        }
      }
      /* -------------------------------------------------------------- */
      /* Selected */
      /* -------------------------------------------------------------- */
      &.selected {
        background: $blue;
        border-color: $blue;
        color: $white;
        &:focus {
          text-decoration-color: $white;
        }
      }
      /* -------------------------------------------------------------- */
      /* Disabled */
      /* -------------------------------------------------------------- */
      &:disabled[class] {
        background-color: $blue-pastel;
        border-color: $blue-pastel;
        color: $blue-pastel-dark;
        cursor: wait;
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Legend */
  /* -------------------------------------------------------------- */
  .legend {
    display: flex;
    flex-wrap: wrap;
    margin: rem(-3px) 0;
    padding: rem(15px) 0 rem(23px);
    li {
      align-items: center;
      display: flex;
      font-size: rem(12px);
      line-height: rem(13px);
      margin: rem(3px) rem(19px) rem(3px) 0;
      padding-left: rem(1px);
      position: relative;
      &:last-child {
        margin-right: 0;
      }
      /* -------------------------------------------------------------- */
      /* Icon */
      /* -------------------------------------------------------------- */
      > span:nth-child(1) {
        border-radius: 50%;
        display: block;
        height: rem(6px);
        margin-right: rem(5px);
        width: rem(6px);
      }
      /* -------------------------------------------------------------- */
      /* Text */
      /* -------------------------------------------------------------- */
      > span:nth-child(2) {
        color: $blue-x-dark;
        position: relative;
        text-transform: capitalize;
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Chart Container */
  /* -------------------------------------------------------------- */
  .chart-container {
    height: rem(185px);
    margin: rem(-9px) rem(-4px) rem(-2px) rem(-1px);
  }
  figure {
    margin: rem(-5px) 0 0;
  }
  /* -------------------------------------------------------------- */
  /* Chart Figcaption */
  /* -------------------------------------------------------------- */
  figcaption {
    top: 0;
    // Required - otherwise table creates additional height on iOS and Android
    // causing body scrolling when phone is oriented horizontally
  }
  /* -------------------------------------------------------------- */
  /* Cartesian Grid */
  /* -------------------------------------------------------------- */
  :global(.recharts-cartesian-grid) {
    line {
      stroke: $grey-light;
      stroke-width: rem(1px);
    }
  }
  /* -------------------------------------------------------------- */
  /* Axis Ticks */
  /* -------------------------------------------------------------- */
  :global(.recharts-cartesian-axis-tick) {
    font-size: rem(8px);
    line-height: rem(10px);
    > text {
      fill: $grey-dark;
    }
  }
}
