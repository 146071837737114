/* -------------------------------------------------------------- */
/* HTML */
/* -------------------------------------------------------------- */
html {
  color: $grey-dark;
  font-family: $primary;
  font-size: 62.5%;
  height: 100%;
  line-height: 1.5;
}
/* -------------------------------------------------------------- */
/* Body */
/* -------------------------------------------------------------- */
body {
  background-color: $green-pastel-light;
  height: 100%;
  min-width: rem(1024px);
  overflow-y: hidden;
  width: 100%;
  @include transition($props: 'min-width', $duration: 0.35s);
  /* ---------------------------------------------------------------- */
  /* Menu Open */
  /* ---------------------------------------------------------------- */
  .menu-open & {
    min-width: rem(1254px);
  }
}
/* -------------------------------------------------------------- */
/* Root */
/* -------------------------------------------------------------- */
#root {
  display: flex;
  height: 100%;
}
/* -------------------------------------------------------------- */
/* Visually Hidden */
/* -------------------------------------------------------------- */
.visually-hidden:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
