@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ------------------------------------------------------------ */
/* Infinite Loader */
/* ------------------------------------------------------------ */
.infinite-loader {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: rem(50px);
  justify-content: center;
  /* ------------------------------------------------------------ */
  /* Loading Animation */
  /* ------------------------------------------------------------ */
  > div {
    display: flex;
    opacity: 0;
    @include transition(
      $props: 'opacity',
      $duration: '0.3s',
      $timing: 'ease-in'
    );
    /* ------------------------------------------------------------ */
    /* Circle */
    /* ------------------------------------------------------------ */
    > div {
      animation: bounce 0.5s ease-in infinite;
      background-color: $red;
      border-radius: 50%;
      flex-shrink: 0;
      height: rem(10px);
      margin: rem(2px);
      width: rem(10px);
      &:nth-child(2) {
        animation-delay: 0.1s;
      }
      &:nth-child(3) {
        animation-delay: 0.2s;
      }
    }
  }
  /* ------------------------------------------------------------ */
  /* In View */
  /* ------------------------------------------------------------ */
  &.in-view {
    > div {
      opacity: 1;
    }
  }
}
/* ------------------------------------------------------------ */
/* Bounce Animation */
/* ------------------------------------------------------------ */
@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(rem(-10px));
  }
}
