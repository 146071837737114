@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* -------------------------------------------------------------- */
/* Skeleton */
/* -------------------------------------------------------------- */
.skeleton {
  position: relative;
  > div {
    height: rem(400px);
  }
  /* -------------------------------------------------------------- */
  /* Spinner */
  /* -------------------------------------------------------------- */
  > div > svg {
    color: $blue-pastel-dark;
    height: rem(60px);
    left: 50%;
    margin: rem(-30px) 0 0 rem(-30px);
    position: absolute;
    top: 50%;
    transform: translateY(rem(-3px));
    width: rem(60px);
    z-index: 1;
    * {
      fill: currentcolor;
    }
  }
  > div > div {
    background-color: $mapbox-background;
    height: 100%;
    width: 100%;
  }
}
