@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Additional Details List - No Results */
/* ---------------------------------------------------------------- */
.no-results {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
  /* ---------------------------------------------------------------- */
  /* Title */
  /* ---------------------------------------------------------------- */
  h2 {
    color: $red;
    font-size: rem(16px);
    font-weight: $extra-bold;
    line-height: rem(21px);
    margin: rem(-4px) 0 rem(-5px);
  }
}
