@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Policy Holders List - No Results */
/* ---------------------------------------------------------------- */
.no-results {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
  /* ---------------------------------------------------------------- */
  /* Icon */
  /* ---------------------------------------------------------------- */
  > svg {
    height: rem(130px);
    margin-bottom: rem(16px);
    width: rem(126px);
  }
  /* ---------------------------------------------------------------- */
  /* Title */
  /* ---------------------------------------------------------------- */
  h2 {
    color: $red;
    font-size: rem(16px);
    font-weight: $extra-bold;
    line-height: rem(21px);
    margin: rem(-4px) 0 rem(-5px);
    padding-bottom: rem(16px);
  }
  /* ---------------------------------------------------------------- */
  /* Text */
  /* ---------------------------------------------------------------- */
  p {
    color: $grey-dark;
    font-size: rem(12px);
    line-height: rem(14px);
    margin: rem(-2px) 0;
  }
}
