@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Invalidate Trip */
/* ---------------------------------------------------------------- */
.invalidate-trip {
  /* ---------------------------------------------------------------- */
  /* Button */
  /* ---------------------------------------------------------------- */
  button {
    width: 100%;
  }
  /* ---------------------------------------------------------------- */
  /* Invalidate Trip Label */
  /* ---------------------------------------------------------------- */
  > div > div {
    background-color: $red;
    border-radius: rem(2px);
    color: $white;
    display: inline-block;
    font-size: rem(12px);
    font-weight: $bold;
    line-height: rem(15px);
    padding: rem(5px) rem(10px);
    @include no-user-select;
  }
}
