@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Drawers */
/* ---------------------------------------------------------------- */
.drawers {
  height: 0;
  position: relative;
  width: 0;
  z-index: 9997;
}
/* ---------------------------------------------------------------- */
/* Drawer Template */
/* ---------------------------------------------------------------- */
.drawers-template {
  background-color: $white;
  box-shadow: 0 rem(3px) rem(6px) rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: rem(32px) rem(20px) rem(106px);
  width: rem(350px);
  /* ---------------------------------------------------------------- */
  /* Header */
  /* ---------------------------------------------------------------- */
  > header {
    align-items: center;
    border-bottom: rem(1px) solid $blue-pastel;
    display: flex;
    padding-bottom: rem(16px);
    position: relative;
    /* ---------------------------------------------------------------- */
    /* Title */
    /* ---------------------------------------------------------------- */
    h2 {
      color: $blue-x-dark;
      font-size: rem(18px);
      font-weight: $bold;
      line-height: rem(22px);
      margin: rem(-4px) 0;
      @include no-user-select;
    }
    /* ---------------------------------------------------------------- */
    /* Button */
    /* ---------------------------------------------------------------- */
    button {
      cursor: pointer;
      height: rem(13px);
      margin-left: auto;
      position: relative;
      width: rem(13px);
      /* ---------------------------------------------------------------- */
      /* Disabled */
      /* ---------------------------------------------------------------- */
      &:disabled {
        cursor: not-allowed;
        svg {
          color: $grey;
        }
      }
      /* ---------------------------------------------------------------- */
      /* Focus */
      /* ---------------------------------------------------------------- */
      &:focus {
        border-radius: 50%;
        outline: rem(2px) solid $blue;
        outline-offset: rem(5px);
      }
    }
    /* ---------------------------------------------------------------- */
    /* Button Icon */
    /* ---------------------------------------------------------------- */
    svg {
      color: $blue;
      height: rem(13px);
      width: rem(13px);
      * {
        fill: currentcolor;
      }
    }
  }
}
