@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Header */
/* ---------------------------------------------------------------- */
.header {
  background-color: $red-dark;
  height: 100%;
  position: relative;
  top: 0;
  width: rem(60px);
  will-change: width;
  @include transition($props: 'width', $duration: 0.35s);
  /* ---------------------------------------------------------------- */
  /* Button */
  /* ---------------------------------------------------------------- */
  > button {
    background-color: $red;
    border-radius: 50%;
    color: $white;
    cursor: pointer;
    height: rem(30px);
    position: absolute;
    right: rem(-15px);
    top: rem(20px);
    transform: translate3d(0, 0, 0);
    width: rem(30px);
    z-index: 0;
    @include transition(
      $mob: true,
      $props: 'background-color, color',
      $duration: 0.35s
    );
    /* ---------------------------------------------------------------- */
    /* Chevron */
    /* ---------------------------------------------------------------- */
    svg {
      color: $white;
      height: rem(12px);
      position: relative;
      right: rem(-7px);
      top: rem(1px);
      transform: rotate(180deg);
      width: rem(7px);
      will-change: transform;
      @include transition($props: 'right, transform', $duration: 0.35s);
      * {
        fill: currentcolor;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Focus Ring */
    /* ---------------------------------------------------------------- */
    &::after {
      border: rem(2px) solid $red-dark;
      border-radius: 50%;
      box-sizing: border-box;
      content: '';
      display: block;
      height: calc(100% + rem(8px));
      opacity: 0;
      position: absolute;
      right: rem(-4px);
      top: rem(-4px);
      width: calc(100% + rem(8px));
    }
    /* ---------------------------------------------------------------- */
    /* Focus */
    /* ---------------------------------------------------------------- */
    &:focus {
      background-color: $red-dark;
      &::after {
        opacity: 1;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Desktop Hover */
    /* ---------------------------------------------------------------- */
    :global(.platform-desktop) & {
      &:hover {
        background-color: $red-dark;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Menu Open */
  /* ---------------------------------------------------------------- */
  :global(.menu-open) & {
    width: rem(292px);
    > button {
      svg {
        right: rem(-5px);
        transform: rotate(0deg);
      }
    }
  }
}
