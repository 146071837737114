@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Event ID */
/* ---------------------------------------------------------------- */
.event-id {
  color: $blue-x-dark;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-left: auto;
  text-align: right;
  /* ---------------------------------------------------------------- */
  /* ID */
  /* ---------------------------------------------------------------- */
  span {
    font-size: rem(14px);
    font-weight: $regular;
    line-height: rem(13px);
    margin-bottom: rem(-1px);
    margin-top: rem(-2px);
  }
}
/* ---------------------------------------------------------------- */
/* Skeleton */
/* ---------------------------------------------------------------- */
.skeleton {
  color: $blue-x-dark;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-left: auto;
  overflow: hidden;
  position: relative;
  text-align: right;
  &::after {
    @include skeleton-shimmer;
    @include skeleton-shimmer-color($color: $white);
  }
  /* ---------------------------------------------------------------- */
  /* ID */
  /* ---------------------------------------------------------------- */
  span {
    font-size: rem(14px);
    font-weight: $regular;
    line-height: rem(13px);
    margin-bottom: rem(-1px);
    margin-top: rem(-2px);
    &::after {
      background-color: $grey-light;
      color: transparent;
      content: 'XXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXX';
      font-size: inherit;
      line-height: inherit;
    }
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
