@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Secondary Nav */
/* ---------------------------------------------------------------- */
.secondary-nav {
  background-color: $red;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  left: rem(60px);
  overflow: hidden;
  padding: rem(20px) 0;
  position: absolute;
  top: 0;
  width: 0;
  z-index: 1;
  @include transition($props: 'width', $duration: 0.35s);
  /* ---------------------------------------------------------------- */
  /* Menu Open */
  /* ---------------------------------------------------------------- */
  :global(.menu-open) & {
    width: rem(232px);
  }
  /* ---------------------------------------------------------------- */
  /* Menu Closed */
  /* ---------------------------------------------------------------- */
  :global(html:not(.menu-open):not(.animating-menu)) & {
    visibility: hidden;
  }
  /* ---------------------------------------------------------------- */
  /* Title */
  /* ---------------------------------------------------------------- */
  p {
    color: $white;
    font-family: $secondary;
    font-size: rem(18px);
    font-weight: $extra-bold;
    line-height: rem(23px);
    margin: rem(-3px) 0 rem(-7px);
    padding: rem(5px) rem(16px) rem(46px);
    text-transform: uppercase;
    width: rem(232px);
    @include no-user-select;
  }
  /* ---------------------------------------------------------------- */
  /* Nav */
  /* ---------------------------------------------------------------- */
  nav {
    box-sizing: border-box;
    height: 100%;
    margin-right: rem(2px);
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 rem(16px);
    scrollbar-color: $white transparent;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: rem(5px);
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: rem(5px);
    }
    &::-webkit-scrollbar-thumb {
      background-color: $white;
      border-radius: rem(5px);
    }
  }
  /* ---------------------------------------------------------------- */
  /* Item */
  /* ---------------------------------------------------------------- */
  li:not(:last-child) {
    margin-bottom: rem(4px);
  }
  /* ---------------------------------------------------------------- */
  /* Link */
  /* ---------------------------------------------------------------- */
  a,
  button {
    align-items: center;
    animation-duration: 0.35s;
    animation-timing-function: ease-in-out;
    border-radius: rem(4px);
    box-sizing: border-box;
    color: $white;
    cursor: pointer;
    display: flex;
    font-size: rem(14px);
    line-height: rem(17px);
    padding: rem(12px);
    position: relative;
    white-space: nowrap;
    width: 100%;
    @include no-user-select;
    @include transition(
      $mob: true,
      $props: 'background-color',
      $duration: 0.35s
    );
    /* ---------------------------------------------------------------- */
    /* Focus */
    /* ---------------------------------------------------------------- */
    &:focus {
      background-color: rgba($red-dark, 0.5);
      text-decoration: underline;
    }
    /* ---------------------------------------------------------------- */
    /* Desktop Hover / Active */
    /* ---------------------------------------------------------------- */
    :global(.platform-desktop) & {
      &:hover {
        background-color: rgba($red-dark, 0.5);
      }
      &:global(.active-animation:not(.nav-link)) {
        animation-name: active;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Mobile Active */
    /* ---------------------------------------------------------------- */
    :global(.platform-mobile) & {
      &:global(.active-animation:not(.nav-link)) {
        animation-name: active;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Current Link */
    /* ---------------------------------------------------------------- */
    &[aria-current='page'] {
      animation-duration: 0.175s;
      animation-fill-mode: forwards;
      animation-name: nav-link-active;
    }
    /* ---------------------------------------------------------------- */
    /* Icon */
    /* ---------------------------------------------------------------- */
    svg {
      flex-shrink: 0;
      height: 100%;
      order: 1;
      width: rem(24px);
      * {
        fill: currentcolor;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Text */
    /* ---------------------------------------------------------------- */
    span {
      margin-left: rem(8px);
      order: 2;
    }
  }
}
/* ---------------------------------------------------------------- */
/* Animations */
/* ---------------------------------------------------------------- */
@keyframes active {
  50% {
    background-color: $red-dark;
  }
}
@keyframes nav-link-active {
  100% {
    background-color: $red-dark;
  }
}
