@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Policy Holders Summary - Report */
/* ---------------------------------------------------------------- */
.report {
  align-items: center;
  font-size: rem(12px);
  font-weight: $regular;
  height: rem(32px);
  margin-left: auto;
  padding: 0 rem(24px) 0 rem(8px);
  position: absolute;
  right: 0;
  /* ---------------------------------------------------------------- */
  /* Text */
  /* ---------------------------------------------------------------- */
  > span:first-child {
    margin-right: rem(12px);
  }
  /* ---------------------------------------------------------------- */
  /* Icon */
  /* ---------------------------------------------------------------- */
  svg {
    color: $white;
    height: rem(16px);
    position: absolute;
    right: rem(8px);
    width: rem(16px);
    * {
      fill: currentcolor;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Spinner */
  /* ---------------------------------------------------------------- */
  > span:last-child {
    height: rem(16px);
    position: absolute;
    right: rem(12px);
    width: rem(16px);
  }
}
