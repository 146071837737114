@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ------------------------------------------------------------ */
/* Question Policy Search */
/* ------------------------------------------------------------ */
.question-policy-search {
  margin: 0;
  width: 100%;
  /* ---------------------------------------------------------------- */
  /* Input Container */
  /* ---------------------------------------------------------------- */
  > div {
    display: flex;
    position: relative;
    /* ---------------------------------------------------------------- */
    /* Input */
    /* ---------------------------------------------------------------- */
    input[type='text'] {
      padding-left: rem(49px);
    }
    /* ---------------------------------------------------------------- */
    /* Search Icon */
    /* ---------------------------------------------------------------- */
    > svg {
      color: $blue-pastel-dark;
      height: rem(20px);
      left: rem(15px);
      margin-top: rem(-10px);
      position: absolute;
      top: 50%;
      width: rem(20px);
      * {
        fill: currentcolor;
      }
    }
  }
}
