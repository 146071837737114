@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Events Summary */
/* ---------------------------------------------------------------- */
.events-summary {
  /* ------------------------------------------------------------ */
  /* No Events */
  /* ------------------------------------------------------------ */
  .no-events {
    display: flex;
    flex-direction: column;
    /* ------------------------------------------------------------ */
    /* Icon */
    /* ------------------------------------------------------------ */
    svg {
      color: $grey;
      flex-shrink: 0;
      height: rem(48px);
      margin: 0 auto rem(16px);
      width: rem(56px);
      * {
        fill: currentcolor;
      }
    }
    /* ------------------------------------------------------------ */
    /* Text */
    /* ------------------------------------------------------------ */
    p {
      color: $grey;
      font-size: rem(12px);
      line-height: rem(13px);
      margin: rem(-2px) 0;
      text-align: center;
      @include no-user-select;
    }
  }
}
