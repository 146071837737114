@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ------------------------------------------------------------ */
/* Spinner */
/* ------------------------------------------------------------ */
.spinner {
  animation: rotate 2s linear infinite;
  display: block;
  height: rem(24px);
  overflow: hidden;
  width: rem(24px);
  will-change: transform;
  /* ------------------------------------------------------------ */
  /* Conic Gradients */
  /* ------------------------------------------------------------ */
  &::before,
  &::after {
    border-radius: 50%;
    content: '';
    height: 100%;
    left: 0;
    mask-image: radial-gradient(
      circle at 50% 50%,
      transparent 56.25%,
      rgba(0, 0, 0, 1) 0%
    );
    mask-repeat: no-repeat;
    mask-size: 100% 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }
  /* ------------------------------------------------------------ */
  /* Ending Colour Gradient */
  /* ------------------------------------------------------------ */
  &::before {
    background: conic-gradient(
      from 83deg at 50% 50%,
      rgba($green-light, 0%) 0deg,
      $green-light 180deg
    );
  }
  /* ------------------------------------------------------------ */
  /* Starting Colour Gradient */
  /* ------------------------------------------------------------ */
  &::after {
    background: conic-gradient(
      from 83deg at 50% 50%,
      rgba($blue, 0) 0deg,
      $blue 360deg
    );
  }
  /* ------------------------------------------------------------ */
  /* Starting Colour Gradient Corner */
  /* ------------------------------------------------------------ */
  span {
    background: $blue;
    border-radius: 50%;
    height: 10%;
    margin-top: -10%;
    position: absolute;
    right: 0.5%;
    top: 50%;
    width: 10%;
  }
}
/* ------------------------------------------------------------ */
/* Animation */
/* ------------------------------------------------------------ */
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  from {
    transform: rotate(-360deg);
  }
}
