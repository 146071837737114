@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Policy Holders Summary - History Rewards Error Message */
/* ---------------------------------------------------------------- */
.history-rewards-error {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: rem(514px);
  justify-content: center;
  text-align: center;
  /* ---------------------------------------------------------------- */
  /* Icon */
  /* ---------------------------------------------------------------- */
  > svg {
    color: $grey;
    flex-shrink: 0;
    height: rem(40px);
    margin: rem(20px) auto rem(16px);
    width: rem(40px);
    * {
      fill: currentcolor;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Name */
  /* ---------------------------------------------------------------- */
  > div > div > h2 {
    color: $grey;
    font-size: rem(18px);
    font-weight: $bold;
    line-height: rem(22px);
    margin: rem(-5px) 0 rem(-4px);
    padding-bottom: rem(16px);
    text-align: center;
  }
  /* ---------------------------------------------------------------- */
  /* Message */
  /* ---------------------------------------------------------------- */
  p {
    color: $grey;
    font-size: rem(16px);
    line-height: rem(19px);
    margin: rem(-4px) 0 rem(-3px);
    padding-bottom: rem(16px);
    text-align: center;
  }
  /* ---------------------------------------------------------------- */
  /* Button */
  /* ---------------------------------------------------------------- */
  button {
    align-self: center;
    justify-self: center;
    margin-top: rem(16px);
  }
}
