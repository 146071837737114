@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ------------------------------------------------------------ */
/* Trips Summary - No More Trips */
/* ------------------------------------------------------------ */
.no-more-trips {
  font-size: rem(12px);
  line-height: rem(13px);
  margin: rem(-2px) 0;
  padding: rem(15px) 0 rem(24px);
  text-align: center;
}
