@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Notifications */
/* ---------------------------------------------------------------- */
.notifications {
  align-items: flex-end;
  bottom: 0;
  display: flex;
  flex-direction: column-reverse;
  height: rem(120px);
  overflow: hidden;
  padding: rem(20px);
  position: fixed;
  right: 0;
  z-index: 9999;
}
/* ---------------------------------------------------------------- */
/* Notification Template */
/* ---------------------------------------------------------------- */
.notifications-template {
  align-items: center;
  animation-duration: 450ms;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  background-color: $white;
  box-shadow: 0 rem(3px) rem(6px) rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  color: $grey-dark;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: rem(40px);
  margin-top: rem(10px);
  overflow: hidden;
  padding: 0 rem(12px);
  width: rem(250px);
  will-change: transform, margin-bottom;
  @include transition($props: 'all', $duration: 0.35s);
  /* ---------------------------------------------------------------- */
  /* SVGs */
  /* ---------------------------------------------------------------- */
  svg {
    color: $grey-dark;
    flex-shrink: 0;
    * {
      fill: currentcolor;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Icon */
  /* ---------------------------------------------------------------- */
  svg:nth-of-type(1) {
    height: rem(12px);
    margin-right: rem(12px);
    width: rem(12px);
  }
  /* ---------------------------------------------------------------- */
  /* Text */
  /* ---------------------------------------------------------------- */
  p {
    font-size: rem(12px);
    line-height: rem(14px);
    padding-right: rem(12px);
  }
  /* ---------------------------------------------------------------- */
  /* Close Icon */
  /* ---------------------------------------------------------------- */
  svg:nth-of-type(2) {
    height: rem(10px);
    margin-left: auto;
    width: rem(10px);
  }
  /* ---------------------------------------------------------------- */
  /* Error */
  /* ---------------------------------------------------------------- */
  &.error {
    background-color: $red;
    color: $white;
    svg {
      color: $white;
    }
    /* ---------------------------------------------------------------- */
    /* Desktop Hover */
    /* ---------------------------------------------------------------- */
    :global(.platform-desktop) & {
      &:hover {
        background-color: darken($red, 10%);
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Info */
  /* ---------------------------------------------------------------- */
  &.info {
    background-color: $blue;
    color: $white;
    svg {
      color: $white;
    }
    /* ---------------------------------------------------------------- */
    /* Desktop Hover */
    /* ---------------------------------------------------------------- */
    :global(.platform-desktop) & {
      &:hover {
        background-color: darken($blue, 10%);
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Success */
  /* ---------------------------------------------------------------- */
  &.success {
    background-color: $green-dark;
    color: $white;
    svg {
      color: $white;
    }
    /* ---------------------------------------------------------------- */
    /* Desktop Hover */
    /* ---------------------------------------------------------------- */
    :global(.platform-desktop) & {
      &:hover {
        background-color: darken($green-dark, 10%);
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Warning */
  /* ---------------------------------------------------------------- */
  &.warning {
    background-color: $yellow;
    color: $white;
    svg {
      color: $white;
    }
    /* ---------------------------------------------------------------- */
    /* Desktop Hover */
    /* ---------------------------------------------------------------- */
    :global(.platform-desktop) & {
      &:hover {
        background-color: darken($yellow, 10%);
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Visible */
  /* ---------------------------------------------------------------- */
  &.visible {
    animation-duration: 225ms;
    animation-name: slide-in;
  }
  /* ---------------------------------------------------------------- */
  /* Hiding */
  /* ---------------------------------------------------------------- */
  &.hiding {
    animation-name: slide-out;
  }
}
/* ---------------------------------------------------------------- */
/* Notification Animations */
/* ---------------------------------------------------------------- */
@keyframes slide-in {
  0% {
    transform: translateX(calc(100% + rem(20px)));
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slide-out {
  0% {
    margin-bottom: 0;
    transform: translateX(0);
  }
  50% {
    margin-bottom: 0;
    transform: translateX(calc(100% + rem(20px)));
  }
  100% {
    margin-bottom: rem(-50px);
    transform: translateX(calc(100% + rem(20px)));
  }
}
