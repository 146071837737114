@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Invalidate Trip - Skeleton */
/* ---------------------------------------------------------------- */
.skeleton {
  overflow: hidden;
  position: relative;
  /* ---------------------------------------------------------------- */
  /* Invalidate Trip Label */
  /* ---------------------------------------------------------------- */
  > div > div {
    background-color: $grey-light;
    border-radius: rem(3px);
    height: rem(44px);
    width: 100%;
    &::after {
      content: '';
      @include skeleton-shimmer;
      @include skeleton-shimmer-color($color: $white);
    }
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
