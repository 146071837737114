@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Policy Number */
/* ---------------------------------------------------------------- */
.policy-number {
  color: $blue-x-dark;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-left: auto;
  text-align: right;
  span {
    /* ---------------------------------------------------------------- */
    /* Label */
    /* ---------------------------------------------------------------- */
    &:first-child {
      font-size: rem(10px);
      font-weight: $bold;
      margin: rem(-4px) 0 rem(-2px);
    }
    /* ---------------------------------------------------------------- */
    /* Number */
    /* ---------------------------------------------------------------- */
    &:last-child {
      font-size: rem(14px);
      font-weight: $regular;
      line-height: rem(13px);
      margin-bottom: rem(-1px);
    }
  }
}
/* ---------------------------------------------------------------- */
/* Skeleton */
/* ---------------------------------------------------------------- */
.skeleton {
  color: $blue-x-dark;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-left: auto;
  overflow: hidden;
  position: relative;
  text-align: right;
  &::after {
    @include skeleton-shimmer;
    @include skeleton-shimmer-color($color: $white);
  }
  span {
    position: relative;
    /* ---------------------------------------------------------------- */
    /* Label */
    /* ---------------------------------------------------------------- */
    &:first-child {
      font-size: rem(10px);
      font-weight: $bold;
      margin: rem(-4px) 0 rem(-2px);
      &::before {
        background-color: $green-pastel-light;
        bottom: rem(2.8px);
        content: '';
        height: 1px;
        left: 0;
        position: absolute;
        right: 0;
        z-index: 1;
      }
      &::after {
        background-color: $grey-light;
        color: transparent;
        content: 'Policy Number';
        font-size: inherit;
        line-height: inherit;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Policy Id */
    /* ---------------------------------------------------------------- */
    &:last-child {
      font-size: rem(14px);
      font-weight: $regular;
      line-height: rem(13px);
      margin-bottom: rem(-1px);
      &::after {
        background-color: $grey-light;
        color: transparent;
        content: '0000 00 0001';
        font-size: inherit;
        line-height: inherit;
      }
    }
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
