@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Toggle Button */
/* ---------------------------------------------------------------- */
.toggle-form-button {
  color: $blue-x-dark;
  cursor: pointer;
  font-size: rem(12px);
  line-height: rem(13px);
  margin-bottom: rem(20px);
  position: relative;
  text-decoration: underline;
  @include no-user-select;
  @include transition($props: 'color', $duration: 0.35s);
  &:only-child {
    margin: 0;
  }
  :global(html:not(.browser-safari)) & {
    text-underline-offset: rem(2px);
  }
  /* ---------------------------------------------------------------- */
  /* Spinner */
  /* ---------------------------------------------------------------- */
  > svg {
    color: $grey;
    height: rem(12px);
    position: absolute;
    right: rem(-6px);
    top: rem(1.8px);
    transform: translateX(100%);
    width: rem(13px);
    path {
      fill: currentcolor;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Focus */
  /* ---------------------------------------------------------------- */
  &:focus {
    color: $red;
  }
  /* ---------------------------------------------------------------- */
  /* Desktop Hover */
  /* ---------------------------------------------------------------- */
  :global(.platform-desktop) & {
    &:not(:disabled):hover {
      color: $blue;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Disabled */
  /* ---------------------------------------------------------------- */
  &:disabled {
    color: $grey;
    cursor: not-allowed;
  }
}
