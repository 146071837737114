@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Policy Holders Summary - Details Skeleton */
/* ---------------------------------------------------------------- */
.policy-details-skeleton {
  /* ---------------------------------------------------------------- */
  /* Header */
  /* ---------------------------------------------------------------- */
  header {
    align-items: center;
    display: flex;
    position: relative;
    /* ---------------------------------------------------------------- */
    /* Icon */
    /* ---------------------------------------------------------------- */
    svg {
      height: rem(15px);
      margin-right: rem(11px);
      order: 1;
      width: rem(14px);
    }
    /* ---------------------------------------------------------------- */
    /* Title */
    /* ---------------------------------------------------------------- */
    h2 {
      order: 2;
    }
    /* ---------------------------------------------------------------- */
    /* Border */
    /* ---------------------------------------------------------------- */
    &::after {
      background-color: $grey-light;
      bottom: 0;
      content: '';
      height: rem(1px);
      left: rem(20px);
      position: absolute;
      right: rem(20px);
      width: calc(100% - rem(40px));
    }
  }
  /* ---------------------------------------------------------------- */
  /* Content */
  /* ---------------------------------------------------------------- */
  > div {
    display: grid;
    grid-gap: rem(20px);
    overflow: hidden;
    position: relative;
    &::after {
      @include skeleton-shimmer;
      @include skeleton-shimmer-color($color: $white);
    }
  }
  /* ---------------------------------------------------------------- */
  /* Detail List */
  /* ---------------------------------------------------------------- */
  ul {
    display: grid;
    grid-gap: rem(20px);
    grid-template-columns: repeat(2, 1fr);
    margin-top: rem(20px);
  }
  /* ---------------------------------------------------------------- */
  /* Detail Item */
  /* ---------------------------------------------------------------- */
  li {
    color: $grey-dark;
    display: grid;
    grid-gap: rem(6px);
  }
  /* ---------------------------------------------------------------- */
  /* Label */
  /* ---------------------------------------------------------------- */
  strong {
    font-size: rem(8px);
    font-weight: $extra-bold;
    line-height: rem(10px);
    margin: rem(-1px) 0 rem(-2px);
    text-transform: uppercase;
    @include no-user-select;
    &::after {
      background-color: $grey-light;
      color: transparent;
      font-size: inherit;
      line-height: inherit;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Value */
  /* ---------------------------------------------------------------- */
  li span {
    font-size: rem(14px);
    line-height: rem(17px);
    margin: rem(-3px) 0;
    &::after {
      background-color: $grey-light;
      color: transparent;
      font-size: inherit;
      line-height: inherit;
    }
  }
  li:nth-child(1) {
    strong::after {
      content: 'NAME';
    }
    span::after {
      content: 'Joe Bloggs';
    }
  }
  li:nth-child(2) {
    strong::after {
      content: 'POLICY NUMBER';
    }
    span::after {
      content: '0385 7935 8394';
    }
  }
  li:nth-child(3) {
    strong::after {
      content: 'EMAIL ADDRESS';
    }
    span::after {
      content: 'jbloggs@email.com';
    }
  }
  li:nth-child(4) {
    strong::after {
      content: 'EXTERNAL ID';
    }
    span::after {
      content: 'ABC-1234-DEF-5678';
    }
  }
  /* ---------------------------------------------------------------- */
  /* Button */
  /* ---------------------------------------------------------------- */
  > div > span {
    background-color: $grey-light;
    border-radius: rem(3px);
    height: rem(44px);
    width: rem(203px);
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
