@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Drawer Overlay */
/* ---------------------------------------------------------------- */
.drawer-overlay {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  min-width: rem(1024px);
  position: fixed;
  right: 0;
  top: 0;
  z-index: 98;
  &:focus {
    outline: none;
  }
  /* ---------------------------------------------------------------- */
  /* Overlay */
  /* ---------------------------------------------------------------- */
  &::before {
    animation-duration: 0.35s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    backdrop-filter: blur(2px);
    background-color: rgba(0, 0, 0, 0.25);
    bottom: 0;
    content: '';
    display: block;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    will-change: opacity;
  }
  /* ---------------------------------------------------------------- */
  /* Dialog Container */
  /* ---------------------------------------------------------------- */
  > div {
    animation-duration: 0.35s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
  /* ---------------------------------------------------------------- */
  /* Open */
  /* ---------------------------------------------------------------- */
  &.slide-in {
    > div {
      will-change: transform;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Remove Animation From Previous Modals If More Than One Active */
  /* ---------------------------------------------------------------- */
  &:not(:only-child):not(:last-child) {
    > div {
      animation: none;
      opacity: 0;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Open */
  /* ---------------------------------------------------------------- */
  &.open {
    &::before {
      animation-name: fade-in;
    }
    &.slide-in > div {
      animation-name: slide-in;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Closing */
  /* ---------------------------------------------------------------- */
  &.closing {
    &::before {
      animation-name: fade-out;
    }
    &.slide-in > div {
      animation-name: slide-out;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Animating */
  /* ---------------------------------------------------------------- */
  &.animating > div {
    pointer-events: none;
    @include no-user-select;
  }
}
/* ---------------------------------------------------------------- */
/* Overlay Animations */
/* ---------------------------------------------------------------- */
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/* ---------------------------------------------------------------- */
/* Drawer Slide In Animations */
/* ---------------------------------------------------------------- */
@keyframes slide-in {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slide-out {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}
