@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Policy Holders - Results Count */
/* ---------------------------------------------------------------- */
.results-count {
  color: $grey-dark;
  font-size: rem(12px);
  line-height: rem(14px);
  margin: rem(-2px) 0;
  padding-bottom: rem(12px);
  strong {
    font-weight: $bold;
  }
}
/* ---------------------------------------------------------------- */
/* Skeleton */
/* ---------------------------------------------------------------- */
.skeleton {
  display: inline-block;
  font-size: rem(12px);
  line-height: rem(14px);
  margin: rem(-2px) 0;
  overflow: hidden;
  padding-bottom: rem(12px);
  position: relative;
  width: rem(157px);
  &::before {
    @include skeleton-shimmer;
    @include skeleton-shimmer-color($color: $white);
  }
  &::after {
    background-color: $grey-light;
    color: transparent;
    content: 'Showing 2 results for "90929"';
    font-size: inherit;
    line-height: inherit;
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
