@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Error Message */
/* ---------------------------------------------------------------- */
.error-fallback {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  min-width: rem(1024px);
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  /* ---------------------------------------------------------------- */
  /* Overlay */
  /* ---------------------------------------------------------------- */
  &::before {
    backdrop-filter: blur(2px);
    background-color: rgba(0, 0, 0, 0.25);
    bottom: 0;
    content: '';
    display: block;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
  }
  /* ---------------------------------------------------------------- */
  /* Container */
  /* ---------------------------------------------------------------- */
  [role='alert'] {
    background-color: $white;
    border-radius: rem(5px);
    box-shadow: 0 rem(12px) rem(24px) rgb(0 0 0 / 10%);
    display: flex;
    flex-direction: column;
    max-height: calc(100% - rem(48px));
    max-width: calc(100% - rem(48px));
    overflow: hidden;
    width: rem(480px);
    z-index: 1;
    /* ---------------------------------------------------------------- */
    /* Header */
    /* ---------------------------------------------------------------- */
    header {
      padding: rem(32px) rem(20px) rem(24px);
      > h2 {
        color: $grey-dark;
        font-size: rem(18px);
        font-weight: 700;
        line-height: rem(22px);
        margin: rem(-4px) 0;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Message */
    /* ---------------------------------------------------------------- */
    > div {
      padding: 0 rem(20px) rem(32px);
      > p,
      > pre {
        font-size: rem(16px);
        line-height: rem(19px);
        margin: rem(-4px) 0 rem(-3px);
      }
      > pre {
        white-space: normal;
      }
      /* ---------------------------------------------------------------- */
      /* Link */
      /* ---------------------------------------------------------------- */
      a {
        color: $red;
        @include transition($props: 'color', $duration: 0.35s);
        &:focus {
          color: $red-dark;
          text-decoration: underline;
        }
        :global(.platform-desktop) & {
          &:hover {
            color: $red-dark;
          }
          &:active {
            color: darken($red-dark, 10%);
          }
        }
        :global(.platform-mobile) & {
          &:active {
            color: $red-dark;
          }
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Footer */
    /* ---------------------------------------------------------------- */
    footer {
      background-color: $green-pastel-light;
      display: flex;
      justify-content: flex-end;
      padding: rem(20px);
      /* ---------------------------------------------------------------- */
      /* Button */
      /* ---------------------------------------------------------------- */
      > button {
        align-self: center;
      }
    }
  }
}
/* ---------------------------------------------------------------- */
/* Nav | Behind the alert */
/* ---------------------------------------------------------------- */
.error-fallback-nav {
  align-items: flex-start;
  background-color: $red-dark;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: rem(20px) rem(8px);
  position: relative;
  width: rem(60px);
  .outer-button {
    background-color: $red;
    border-radius: 50%;
    color: $white;
    cursor: pointer;
    height: rem(30px);
    position: absolute;
    right: rem(-15px);
    top: rem(20px);
    width: rem(30px);
    z-index: -1;
    /* ---------------------------------------------------------------- */
    /* Chevron */
    /* ---------------------------------------------------------------- */
    svg {
      color: $white;
      height: rem(12px);
      position: relative;
      right: rem(-17px);
      top: rem(8px);
      transform: rotate(180deg);
      width: rem(7px);
      * {
        fill: currentcolor;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Icons */
  /* ---------------------------------------------------------------- */
  > div {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: rem(40px);
    width: 100%;
    span {
      align-items: center;
      background-color: $red;
      border-radius: 50%;
      cursor: help;
      display: flex;
      flex-shrink: 0;
      height: rem(36px);
      justify-content: center;
      margin-bottom: rem(28px);
      position: relative;
      width: rem(36px);
      z-index: 0;
    }
    svg {
      width: rem(24px);
      &:last-child {
        margin-top: rem(32px);
      }
      * {
        fill: $white;
      }
    }
  }
}
