@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Global Nav */
/* ---------------------------------------------------------------- */
.global-nav {
  align-items: center;
  background-color: $red-dark;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: rem(20px) rem(8px);
  position: relative;
  width: rem(60px);
  z-index: 2;
  /* ---------------------------------------------------------------- */
  /* Logo */
  /* ---------------------------------------------------------------- */
  > svg {
    flex-shrink: 0;
    height: rem(24px);
    width: rem(44px);
  }
  /* ---------------------------------------------------------------- */
  /* Account Name */
  /* ---------------------------------------------------------------- */
  > div {
    align-items: center;
    background-color: rgba($red, 0.5);
    border-radius: 50%;
    cursor: help;
    display: flex;
    flex-shrink: 0;
    height: rem(36px);
    justify-content: center;
    margin: rem(40px) 0 rem(16px);
    position: relative;
    width: rem(36px);
    z-index: 10;
    @include transition(
      $mob: false,
      $props: 'background-color',
      $duration: 0.35s
    );
    > span {
      color: $white;
      display: block;
      font-family: $secondary;
      font-size: rem(15px);
      font-weight: $extra-bold;
      line-height: 1;
      margin-top: rem(3px);
      text-shadow: 0 0 rem(1px) rgba(0, 0, 0, 0.1);
      @include no-user-select;
    }
    :global(.platform-desktop) & {
      &:hover {
        background-color: $red;
        > div:last-child {
          opacity: 1;
          transform: scale(1);
          transition-delay: 1s;
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Account Name Tooltip */
  /* ---------------------------------------------------------------- */
  > div > div:last-child {
    background-color: $grey-x-dark;
    border-radius: rem(5px);
    color: $grey-light;
    font-family: $primary;
    font-size: rem(14px);
    left: 0;
    line-height: rem(18px);
    opacity: 0;
    padding: rem(6px) rem(10px);
    pointer-events: none;
    position: absolute;
    top: calc(100% + rem(8px));
    transform: scale(0.75);
    transform-origin: rem(18px) 0;
    will-change: opacity, transform;
    @include transition(
      $mob: false,
      $props: 'opacity, transform',
      $duration: 0.25s
    );
    > span {
      color: $white;
      font-weight: $bold;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Nav */
  /* ---------------------------------------------------------------- */
  nav {
    height: 100%;
    padding-top: rem(12px);
    width: calc(100% + rem(16px));
  }
  /* ---------------------------------------------------------------- */
  /* List */
  /* ---------------------------------------------------------------- */
  ul {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  /* ---------------------------------------------------------------- */
  /* Item */
  /* ---------------------------------------------------------------- */
  li {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    &:not(:last-child) {
      margin-bottom: rem(32px);
    }
  }
  /* ---------------------------------------------------------------- */
  /* Link */
  /* ---------------------------------------------------------------- */
  a,
  button {
    align-items: center;
    color: $white;
    cursor: pointer;
    display: flex;
    touch-action: manipulation;
    width: rem(24px);
    /* ---------------------------------------------------------------- */
    /* Desktop Hover / Focus Ring */
    /* ---------------------------------------------------------------- */
    &::after {
      background-color: transparent;
      border: rem(2px) solid transparent;
      border-radius: 50%;
      content: '';
      display: block;
      flex-shrink: 0;
      height: rem(36px);
      left: 50%;
      opacity: 1;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(-50%, -50%);
      width: rem(36px);
      z-index: -1;
      @include transition(
        $props: 'background-color, border-color',
        $duration: '0.35s'
      );
    }
    /* ---------------------------------------------------------------- */
    /* Label Tooltip */
    /* ---------------------------------------------------------------- */
    span {
      background-color: $grey-x-dark;
      border-radius: rem(3px);
      color: $white;
      display: block;
      font-size: rem(10px);
      left: 0;
      line-height: 1;
      opacity: 0;
      padding: rem(4px);
      position: absolute;
      transform: translateX(0);
      transition: opacity 0.35s ease-in-out, transform 0.35s ease-in-out,
        left 0s 0.35s ease-in-out;
      white-space: nowrap;
      will-change: opacity, transform;
    }
    /* ---------------------------------------------------------------- */
    /* Focus */
    /* ---------------------------------------------------------------- */
    &:focus {
      &::after {
        background-color: $red;
        border-color: rgba($white, 0.75);
      }
      span {
        left: 100%;
        opacity: 1;
        transform: translateX(rem(10px));
        transition: opacity 0.35s ease-in-out, transform 0.35s ease-in-out,
          left 0s 0s ease-in-out;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Desktop Hover */
    /* ---------------------------------------------------------------- */
    :global(.platform-desktop) & {
      &:hover {
        &::after {
          background-color: $red;
        }
        span {
          left: 100%;
          opacity: 1;
          transform: translateX(rem(8px));
          transition: opacity 0.35s ease-in-out, transform 0.35s ease-in-out,
            left 0s 0s ease-in-out;
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Current Page */
    /* ---------------------------------------------------------------- */
    &::before {
      background-color: $red;
      content: '';
      height: rem(50px);
      left: 0;
      opacity: 0;
      position: absolute;
      right: 0;
      z-index: -1;
      @include transition($props: 'opacity', $duration: '0.35s');
    }
    &[aria-current='page'] {
      &::before {
        opacity: 1;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Icon */
    /* ---------------------------------------------------------------- */
    svg {
      height: 100%;
      pointer-events: none;
      width: 100%;
      * {
        fill: currentcolor;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Admin */
  /* ---------------------------------------------------------------- */
  &.admin {
    > svg {
      cursor: pointer;
    }
  }
}
