@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ------------------------------------------------------------ */
/* Question Policy Holders Search */
/* ------------------------------------------------------------ */
.question-policy-holders-search {
  margin: 0;
  width: 100%;
  /* ---------------------------------------------------------------- */
  /* Input Container */
  /* ---------------------------------------------------------------- */
  > div {
    display: flex;
    position: relative;
    /* ---------------------------------------------------------------- */
    /* Seperator */
    /* ---------------------------------------------------------------- */
    &::after {
      background-color: $blue-pastel;
      content: '';
      height: rem(30px);
      position: absolute;
      right: rem(38px);
      top: rem(7px);
      width: rem(1px);
    }
  }
  /* ---------------------------------------------------------------- */
  /* Input */
  /* ---------------------------------------------------------------- */
  input[type='text'] {
    padding-right: rem(48px);
  }
  /* ---------------------------------------------------------------- */
  /* Value */
  /* ---------------------------------------------------------------- */
  &.value {
    [type='submit'] {
      padding-left: rem(6px);
      width: rem(32px);
    }
    > div::after {
      right: rem(95px);
    }
    input {
      padding-right: rem(105px);
    }
  }
}
