@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Policy Container */
/* ---------------------------------------------------------------- */
.policy-container[class] {
  width: rem(400px);
  + div[class]:last-child {
    flex-grow: 1;
    width: calc(100% - rem(400px));
  }
}
