@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Drawer Template - Additional Detail Field Input */
/* ---------------------------------------------------------------- */
.additional-detail-field-input {
  align-items: center;
  display: flex;
  /* ---------------------------------------------------------------- */
  /* Input Container */
  /* ---------------------------------------------------------------- */
  > div {
    width: 100%;
  }
  /* ---------------------------------------------------------------- */
  /* Buttons */
  /* ---------------------------------------------------------------- */
  button {
    align-items: center;
    background-color: transparent;
    border: rem(1px) solid $blue-pastel;
    border-radius: 50%;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-shrink: 0;
    height: rem(18px);
    justify-content: center;
    margin-left: rem(10px);
    width: rem(18px);
    @include transition(
      $mob: true,
      $props: 'background-color, border-color',
      $duration: 0.35s
    );
    svg * {
      fill: currentcolor;
    }
    /* ---------------------------------------------------------------- */
    /* Disabled */
    /* ---------------------------------------------------------------- */
    &:disabled {
      background-color: rgba($grey, 0.1);
      border-color: $grey;
      cursor: not-allowed;
      svg[aria-hidden='true'] {
        color: $grey;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Focus */
    /* ---------------------------------------------------------------- */
    &:focus {
      background-color: $blue-pastel-light;
      border-color: $blue;
    }
    /* ---------------------------------------------------------------- */
    /* Hover */
    /* ---------------------------------------------------------------- */
    :global(.platform-desktop) & {
      &:hover:not(:disabled) {
        background-color: $blue-pastel-light;
        border-color: $blue;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Cancel Button Icon */
  /* ---------------------------------------------------------------- */
  button:nth-of-type(1) svg {
    color: $red;
    height: rem(7px);
    width: rem(7px);
  }
  /* ---------------------------------------------------------------- */
  /* Save Button Icon */
  /* ---------------------------------------------------------------- */
  button:nth-of-type(2) svg {
    color: $green;
    height: rem(7px);
    width: rem(9px);
  }
  /* ---------------------------------------------------------------- */
  /* Input */
  /* ---------------------------------------------------------------- */
  input[type='text'] {
    background: $blue-pastel-light;
    color: $grey-x-dark;
    font-size: rem(10px);
    height: rem(22px);
    padding: 0 rem(4px);
    &:not(:focus) {
      border-color: $blue-pastel;
    }
  }
}
