@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Policy Holders Summary - Points */
/* ---------------------------------------------------------------- */
.points-balance {
  /* ---------------------------------------------------------------- */
  /* Header */
  /* ---------------------------------------------------------------- */
  header {
    align-items: center;
    display: flex;
    position: relative;
    /* ---------------------------------------------------------------- */
    /* Icon */
    /* ---------------------------------------------------------------- */
    svg {
      color: $grey-x-dark;
      height: rem(15px);
      margin-right: rem(11px);
      order: 1;
      width: rem(14px);
      * {
        fill: currentcolor;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Title */
    /* ---------------------------------------------------------------- */
    h2 {
      order: 2;
    }
    /* ---------------------------------------------------------------- */
    /* Border */
    /* ---------------------------------------------------------------- */
    &::after {
      background-color: $grey-light;
      bottom: 0;
      content: '';
      height: rem(1px);
      left: rem(20px);
      position: absolute;
      right: rem(20px);
      width: calc(100% - rem(40px));
    }
  }
  /* ---------------------------------------------------------------- */
  /* Content */
  /* ---------------------------------------------------------------- */
  > div {
    display: grid;
    grid-gap: rem(20px);
  }
  /* ---------------------------------------------------------------- */
  /* Points List */
  /* ---------------------------------------------------------------- */
  ul {
    display: flex;
    flex-wrap: wrap;
    margin-top: rem(20px);
  }
  /* ---------------------------------------------------------------- */
  /* Points Item */
  /* ---------------------------------------------------------------- */
  li {
    display: grid;
    grid-gap: rem(6px);
    padding: rem(7px) rem(8px);
    /* ---------------------------------------------------------------- */
    /* Banked */
    /* ---------------------------------------------------------------- */
    &:nth-child(1) {
      background-color: $blue-x-dark;
      border-radius: rem(5px);
      box-sizing: border-box;
      color: $white;
      margin-right: rem(12px);
      min-width: rem(137px);
    }
    /* ---------------------------------------------------------------- */
    /* Unbanked */
    /* ---------------------------------------------------------------- */
    &:nth-child(2) {
      color: $grey-dark;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Label */
  /* ---------------------------------------------------------------- */
  li > strong {
    align-self: flex-end;
    font-size: rem(8px);
    font-weight: $extra-bold;
    line-height: rem(10px);
    margin: rem(-1px) 0 rem(-2px);
    text-transform: uppercase;
    @include no-user-select;
  }
  /* ---------------------------------------------------------------- */
  /* Value */
  /* ---------------------------------------------------------------- */
  p {
    font-size: rem(12px);
    line-height: rem(14px);
    margin: rem(-5px) 0;
    /* ---------------------------------------------------------------- */
    /* Points */
    /* ---------------------------------------------------------------- */
    strong {
      font-size: rem(24px);
      font-weight: $extra-bold;
      line-height: rem(29px);
    }
  }
  /* ---------------------------------------------------------------- */
  /* Expiry Date */
  /* ---------------------------------------------------------------- */
  li > span {
    font-size: rem(8px);
    font-style: italic;
    line-height: rem(10px);
    margin: rem(-1px) 0 rem(-2px);
  }
  /* ---------------------------------------------------------------- */
  /* Button */
  /* ---------------------------------------------------------------- */
  button {
    justify-self: flex-start;
  }
}
