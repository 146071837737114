@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ------------------------------------------------------------ */
/* Question */
/* ------------------------------------------------------------ */
.question {
  display: block;
  margin: 0 0 rem(20px);
  @include transition($props: 'opacity', $duration: 0.35s);
  /* ---------------------------------------------------------- */
  /* Label */
  /* ---------------------------------------------------------- */
  > label {
    color: $blue-x-dark;
    cursor: pointer;
    display: table;
    @include no-user-select;
    @include transition($props: 'color', $duration: 0.35s);
  }
  /* ---------------------------------------------------------- */
  /* Error */
  /* ---------------------------------------------------------- */
  [aria-live='polite'] {
    align-items: flex-start;
    color: $red;
    display: flex;
    /* ---------------------------------------------------------- */
    /* Icon */
    /* ---------------------------------------------------------- */
    svg {
      flex-shrink: 0;
      * {
        fill: currentcolor;
      }
    }
    /* ---------------------------------------------------------- */
    /* Text */
    /* ---------------------------------------------------------- */
    span {
      display: block;
      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
  /* ---------------------------------------------------------- */
  /* Medium */
  /* ---------------------------------------------------------- */
  &.medium {
    > label,
    [aria-live='polite'] {
      font-size: rem(14px);
      line-height: rem(17px);
    }
    > label {
      margin: rem(-4px) 0 rem(9px);
    }
    [aria-live='polite'] {
      margin: rem(12px) 0 0;
      svg {
        height: rem(16px);
        width: rem(16px);
      }
      span {
        margin: 0 0 rem(-5px);
        padding-left: rem(11px);
      }
    }
  }
  /* ---------------------------------------------------------- */
  /* Small */
  /* ---------------------------------------------------------- */
  &.small {
    > label,
    [aria-live='polite'] {
      font-size: rem(10px);
      line-height: rem(13px);
    }
    > label {
      margin: rem(-3px) 0 rem(6px);
    }
    [aria-live='polite'] {
      margin: rem(8px) 0 0;
      svg {
        height: rem(12px);
        width: rem(12px);
      }
      span {
        margin: 0 0 rem(-4px);
        padding-left: rem(7px);
      }
    }
  }
  /* ---------------------------------------------------------- */
  /* Disabled */
  /* ---------------------------------------------------------- */
  &.disabled {
    > label {
      color: rgba($blue-pastel-dark, 0.4);
      cursor: default;
      pointer-events: none;
    }
  }
  /* ---------------------------------------------------------- */
  /* Readonly */
  /* ---------------------------------------------------------- */
  &.read-only {
    opacity: 0.75;
    > label {
      cursor: default;
    }
  }
  /* ---------------------------------------------------------- */
  /* Invalid */
  /* ---------------------------------------------------------- */
  &.invalid {
    > label {
      color: $red;
    }
  }
}
