@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ------------------------------------------------------------ */
/* Trips Summary */
/* ------------------------------------------------------------ */
.trips-summary-no-trips {
  align-items: center;
  color: $grey;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-size: rem(12px);
  justify-content: center;
  line-height: rem(13px);
  margin: 0 auto;
  text-align: center;
  width: rem(140px);
  /* ------------------------------------------------------------ */
  /* Trips Summary */
  /* ------------------------------------------------------------ */
  > svg {
    flex-shrink: 0;
    height: rem(60px);
    margin-bottom: rem(16px);
    width: rem(70px);
    * {
      fill: currentcolor;
    }
  }
  /* ------------------------------------------------------------ */
  /* Text */
  /* ------------------------------------------------------------ */
  > p {
    margin: rem(-1px) 0 rem(-2px);
    @include no-user-select;
  }
}
