@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Policy Holders Error */
/* ---------------------------------------------------------------- */
.policy-holders-error {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
  /* ---------------------------------------------------------------- */
  /* Container */
  /* ---------------------------------------------------------------- */
  > div {
    align-items: center;
    color: $grey;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    font-size: rem(12px);
    justify-content: center;
    line-height: rem(13px);
    margin: 0 auto;
    text-align: center;
    width: rem(140px);
  }
  /* ---------------------------------------------------------------- */
  /* Icon */
  /* ---------------------------------------------------------------- */
  svg {
    color: $grey;
    flex-shrink: 0;
    height: rem(40px);
    margin: 0 auto rem(16px);
    width: rem(40px);
    * {
      fill: currentcolor;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Name */
  /* ---------------------------------------------------------------- */
  h2 {
    color: $grey;
    font-size: rem(18px);
    font-weight: $bold;
    line-height: rem(22px);
    margin: rem(-5px) 0 rem(-4px);
    padding-bottom: rem(16px);
    text-align: center;
  }
  /* ---------------------------------------------------------------- */
  /* Message */
  /* ---------------------------------------------------------------- */
  p {
    color: $grey;
    font-size: rem(16px);
    line-height: rem(19px);
    margin: rem(-4px) 0 rem(-3px);
    padding-bottom: rem(16px);
    text-align: center;
  }
  /* ---------------------------------------------------------------- */
  /* Button */
  /* ---------------------------------------------------------------- */
  button {
    align-self: center;
  }
}
