@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* -------------------------------------------------------------- */
/* Policy Holders Summary - History Rewards */
/* -------------------------------------------------------------- */
.history-rewards {
  display: flex;
  flex-direction: column;
  /* ---------------------------------------------------------------- */
  /* Footer */
  /* ---------------------------------------------------------------- */
  footer {
    align-items: center;
    display: flex;
    font-size: rem(12px);
    height: rem(34px);
    justify-content: flex-end;
    line-height: 1;
    margin-bottom: rem(-10px);
    padding: 0 rem(10px);
    position: relative;
    strong {
      font-weight: 700;
    }
    /* ---------------------------------------------------------------- */
    /* Button */
    /* ---------------------------------------------------------------- */
    button {
      align-items: center;
      cursor: pointer;
      display: flex;
      height: rem(34px);
      justify-content: center;
      position: relative;
      width: rem(20px);
      /* ---------------------------------------------------------------- */
      /* Disabled */
      /* ---------------------------------------------------------------- */
      &[disabled] {
        cursor: not-allowed;
        svg {
          color: $grey;
        }
      }
      /* ---------------------------------------------------------------- */
      /* Focus Ring */
      /* ---------------------------------------------------------------- */
      &:focus::after {
        border: rem(2px) solid $blue;
        border-radius: 50%;
        content: '';
        display: block;
        height: rem(20px);
        position: absolute;
        width: rem(20px);
      }
      /* ---------------------------------------------------------------- */
      /* Desktop Hover */
      /* ---------------------------------------------------------------- */
      :global(.platform-desktop) & {
        &:hover:not([disabled]) svg {
          color: $blue-dark;
        }
      }
      /* ---------------------------------------------------------------- */
      /* Button Icon */
      /* ---------------------------------------------------------------- */
      svg {
        color: $blue;
        height: rem(10px);
        width: rem(5px);
        @include transition($props: 'color', $duration: '0.35s');
        * {
          stroke: currentcolor;
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Previous Page Button */
    /* ---------------------------------------------------------------- */
    button:nth-of-type(1) {
      margin-left: rem(12px);
      transform: rotate(180deg);
      &:focus::after {
        left: rem(-3px);
      }
    }
    /* ---------------------------------------------------------------- */
    /* Previous Page Button */
    /* ---------------------------------------------------------------- */
    button:nth-of-type(2) {
      margin-left: rem(6px);
      margin-right: rem(-8px);
      &:focus::after {
        left: rem(-2px);
      }
    }
  }
}
