@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Declined Modal - Form */
/* ---------------------------------------------------------------- */
.declined-modal-form {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  &:first-child {
    padding-top: rem(32px);
  }
  /* ---------------------------------------------------------------- */
  /* Field Container */
  /* ---------------------------------------------------------------- */
  > div:first-child {
    padding: 0 rem(20px) rem(23px);
    /* ---------------------------------------------------------------- */
    /* Last Question */
    /* ---------------------------------------------------------------- */
    > div:last-child {
      margin: 0;
    }
  }
}
