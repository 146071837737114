@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Events Timeout  */
/* ---------------------------------------------------------------- */
.events-timeout {
  align-items: center;
  background-color: transparent;
  box-shadow: none;
  flex-grow: 1;
  justify-content: center;
  text-align: center;
  /* ---------------------------------------------------------------- */
  /*  Container  */
  /* ---------------------------------------------------------------- */
  > div {
    margin-top: rem(-4px);
  }
  /* ---------------------------------------------------------------- */
  /* Icon  */
  /* ---------------------------------------------------------------- */
  svg {
    color: $grey;
    flex-shrink: 0;
    height: rem(60px);
    margin-bottom: rem(24px);
    width: rem(60px);
    * {
      fill: currentcolor;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Title  */
  /* ---------------------------------------------------------------- */
  h2 {
    color: $grey-dark;
    font-family: $secondary;
    font-size: rem(24px);
    font-weight: $black;
    line-height: rem(30px);
    margin: rem(-8px) 0 rem(-9px);
    @include no-user-select;
  }
  /* ---------------------------------------------------------------- */
  /* Buttons Container  */
  /* ---------------------------------------------------------------- */
  > div > div {
    margin: rem(24px) rem(-5px) 0;
    button {
      margin: 0 rem(5px);
    }
  }
}
