@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ------------------------------------------------------------ */
/* Map */
/* ------------------------------------------------------------ */
.map {
  position: relative;
  /* ------------------------------------------------------------ */
  /* Container */
  /* ------------------------------------------------------------ */
  > div > div {
    background-color: $mapbox-background;
    height: rem(400px);
    /* ---------------------------------------------------------------- */
    /* Canvas Focus */
    /* ---------------------------------------------------------------- */
    canvas {
      border-radius: rem(3px);
      &:focus-visible {
        outline-color: $red;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Map Controls Container */
    /* ---------------------------------------------------------------- */
    > div:last-child {
      /* ---------------------------------------------------------------- */
      /* Logo Focus */
      /* ---------------------------------------------------------------- */
      div:nth-child(3) > div > a:focus {
        outline: 1px solid $red;
      }
      /* ---------------------------------------------------------------- */
      /* Copyright Links Focus */
      /* ---------------------------------------------------------------- */
      div:last-child > div {
        border-radius: rem(3px);
        > div a:focus {
          color: $red;
        }
      }
    }
    /* ------------------------------------------------------------ */
    /* Map Event Markers */
    /* ------------------------------------------------------------ */
    .map-icon {
      align-items: center;
      animation-duration: 0.5s;
      animation-fill-mode: forwards;
      animation-name: scale-in;
      animation-timing-function: ease-in-out;
      display: flex;
      justify-content: center;
      position: relative;
      transform-origin: 50% 50%;
      > svg {
        bottom: 0;
        display: block;
        height: rem(35px);
        position: absolute;
        width: rem(30px);
      }
      /* ------------------------------------------------------------ */
      /* Start & End Marker */
      /* ------------------------------------------------------------ */
      .journey-icon {
        align-items: center;
        background-color: $blue;
        border-radius: 50%;
        display: flex;
        height: rem(20px);
        justify-content: center;
        width: rem(20px);
        &.start {
          &::after {
            background-color: $white;
            border-radius: 50%;
            content: '';
            height: rem(10px);
            width: rem(10px);
          }
        }
      }
    }
    /* ------------------------------------------------------------ */
    /* Position Markers */
    /* ------------------------------------------------------------ */
    .position-icon {
      align-items: center;
      animation-duration: 0.5s;
      animation-fill-mode: forwards;
      animation-name: scale-in;
      animation-timing-function: ease-in-out;
      display: flex;
      justify-content: center;
      position: relative;
      transform-origin: 50% 50%;
      > div {
        bottom: 0;
        display: block;
        position: absolute;
      }
      svg {
        display: block;
        height: 3.5rem;
        width: 3rem;
      }
      span {
        font-size: 14px;
        left: 50%;
        position: absolute;
        top: 6px;
        transform: translateX(-50%);
      }
    }
  }
}
/* ---------------------------------------------------------------- */
/* Scale In Marker Animation */
/* ---------------------------------------------------------------- */
@keyframes scale-in {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
