@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Policy Holders Form */
/* ---------------------------------------------------------------- */
.policy-holders-form {
  display: grid;
  grid-gap: rem(12px);
  margin-bottom: rem(12px);
  /* ---------------------------------------------------------------- */
  /* Question */
  /* ---------------------------------------------------------------- */
  > div {
    /* ---------------------------------------------------------------- */
    /* Input Container */
    /* ---------------------------------------------------------------- */
    > div {
      align-items: center;
      display: flex;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Input */
  /* ---------------------------------------------------------------- */
  input {
    border-color: $blue-pastel;
  }
  /* ---------------------------------------------------------------- */
  /* Buttons */
  /* ---------------------------------------------------------------- */
  button {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 100%;
    position: absolute;
    /* ---------------------------------------------------------------- */
    /* Disabled */
    /* ---------------------------------------------------------------- */
    &[disabled] {
      pointer-events: none;
    }
    /* ---------------------------------------------------------------- */
    /* Button Icon */
    /* ---------------------------------------------------------------- */
    svg {
      @include transition($mob: false, $props: 'color', $duration: '0.35s');
      * {
        fill: currentcolor;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Submit Button */
  /* ---------------------------------------------------------------- */
  [type='submit'] {
    padding-left: rem(12px);
    right: 0;
    width: rem(38px);
    /* ---------------------------------------------------------------- */
    /* Focus Ring */
    /* ---------------------------------------------------------------- */
    &:focus::after {
      border: rem(2px) solid $blue;
      border-radius: 50%;
      content: '';
      display: block;
      height: rem(24px);
      margin-left: rem(12px);
      position: absolute;
      right: rem(5px);
      width: rem(24px);
    }
    /* ---------------------------------------------------------------- */
    /* Desktop Hover */
    /* ---------------------------------------------------------------- */
    :global(.platform-desktop) & {
      &:hover {
        svg {
          color: $blue-dark;
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Search Icon */
    /* ---------------------------------------------------------------- */
    svg {
      color: $blue;
      height: rem(13px);
      width: rem(13px);
    }
  }
  /* ---------------------------------------------------------------- */
  /* Clear Button */
  /* ---------------------------------------------------------------- */
  [aria-label='Clear'] {
    color: $blue-pastel-dark;
    font-size: rem(12px);
    line-height: 1;
    padding: 0 rem(6px) 0 rem(12px);
    right: rem(32px);
    text-decoration: underline;
    width: rem(63px);
    @include transition($mob: false, $props: 'color', $duration: '0.35s');
    /* ---------------------------------------------------------------- */
    /* Focus Ring */
    /* ---------------------------------------------------------------- */
    &:focus::after {
      border: rem(2px) solid $blue;
      border-radius: rem(5px);
      content: '';
      display: block;
      height: rem(24px);
      left: rem(-8px);
      margin-left: rem(12px);
      position: absolute;
      right: 0;
    }
    /* ---------------------------------------------------------------- */
    /* Desktop Hover */
    /* ---------------------------------------------------------------- */
    :global(.platform-desktop) & {
      &:hover {
        color: $blue-dark;
        svg {
          color: $blue-dark;
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Clear Icon */
    /* ---------------------------------------------------------------- */
    svg {
      color: $blue-pastel-dark;
      height: rem(9px);
      margin-left: rem(8px);
      width: rem(9px);
    }
  }
  /* ---------------------------------------------------------------- */
  /* Loading */
  /* ---------------------------------------------------------------- */
  &.loading {
    /* ---------------------------------------------------------------- */
    /* Button */
    /* ---------------------------------------------------------------- */
    button {
      display: none;
    }
    /* ---------------------------------------------------------------- */
    /* Input */
    /* ---------------------------------------------------------------- */
    input {
      padding-right: rem(53px);
      &[readonly] {
        // background: $white;
        pointer-events: none;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Inout Container */
    /* ---------------------------------------------------------------- */
    > div > div {
      cursor: progress;
      /* ---------------------------------------------------------------- */
      /* Spinner */
      /* ---------------------------------------------------------------- */
      > span {
        height: rem(22px);
        position: absolute;
        right: rem(11px);
        top: rem(11px);
        width: rem(22px);
      }
    }
    /* ---------------------------------------------------------------- */
    /* Seperater */
    /* ---------------------------------------------------------------- */
    > div > div::after {
      right: rem(43px);
    }
  }
}
