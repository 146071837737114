@use 'sass:math';
/* -------------------------------------------------------------- */
/* Pixel To Rem */
/* -------------------------------------------------------------- */
@function rem($pxValue) {
  @return math.div($pxValue, 10px) * 1rem;
}
/* -------------------------------------------------------------- */
/* Skeleton */
/* -------------------------------------------------------------- */
@mixin skeleton-shimmer($duration: '1') {
  animation: shimmer #{$duration}s infinite;
  background-color: transparent;
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(-100%);
}
@mixin skeleton-shimmer-color($color: null) {
  background-image: linear-gradient(
    90deg,
    rgba($color, 0) 0,
    rgba($color, 0.5) 20%,
    rgba($color, 0.8) 60%,
    rgba($color, 0)
  );
}
/* -------------------------------------------------------------- */
/* Transition */
/* -------------------------------------------------------------- */
@mixin transition(
  $mob: true,
  $timing: ease-in-out,
  $props: 'all',
  $duration: '0s',
  $delay: '0s'
) {
  transition-duration: #{$duration};
  transition-property: #{$props};
  transition-timing-function: #{$timing};
  @if not($delay == '0s') {
    transition-delay: #{$delay};
  }
  @if not($mob) {
    :global(.platform-mobile) & {
      transition: none;
    }
  }
}
/* -------------------------------------------------------------- */
/* User Select */
/* -------------------------------------------------------------- */
@mixin user-select() {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
/* -------------------------------------------------------------- */
/* No User Select */
/* -------------------------------------------------------------- */
@mixin no-user-select() {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* -------------------------------------------------------------- */
/* Variable Fonts */
/* -------------------------------------------------------------- */
@mixin variable-fonts($weight: '400', $width: null) {
  font-weight: #{$weight};
  @if $width {
    font-variation-settings: 'wght' #{$weight}, 'wdth' #{$width};
  } @else {
    font-variation-settings: 'wght' #{$weight};
  }
}
